import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../service/core.service';
import { ApiService } from '../../../../service/api.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-rep-top-products',
  templateUrl: './rep-top-products.component.html',
  styleUrls: ['./rep-top-products.component.css']
})
export class RepTopProductsComponent implements OnInit {

 
  pageLoadingImg: boolean;
  curpage: any;
  lastpage: any;
  pgstart: boolean;
  pgend: boolean;
  datas: string[];
  values: string[];
  isnoData : boolean;
  staffs:string[];
  filterStaffs:any;
  repDate:any;date1:any;date2:any;qty1:any;qty_id:any;qty2:any;crate1:any;comm_rate:any;crate2:any;
  elseBlocknoend:any;period_type:any;elseBlocknostart:any;prd_formula:any;usr_id:any;filter:any;
  formula_all: any;
  

 constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    this.readIncre(1);
   // this.getAllSatff();
  //  this.listProductFormula();
  }

  readIncre(pageno)
  {
    this.pageLoadingImg = true;
    this.isnoData = false;
    let searchval = new FormData();
    this.apiService.topProducts(searchval, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.datas = res['data']['data'];      
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.repDate = res['data']['Date'];

      if(this.datas.length==0)
      this.isnoData = true;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
  }

  pageNext(formdata: { value: any; }, pageno: any) {
    formdata.value.filter = 1;   
    this.pageLoadingImg = true;
    this.isnoData = false;
    this.apiService.topProducts(formdata.value, pageno).subscribe((res: any) => {      
      this.datas = res['data']['data'];      
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.repDate = res['data']['Date'];

      this.pageLoadingImg = false;
      if(this.datas.length==0)
      this.isnoData = true;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);

    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;
  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  filterDate = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];
  // filter  types
  filterTypes = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' }
  ];

   //form search
   searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.values = res['data'];
    });
  }

  // listProductFormula() { 
  //   this.productionService.getAllProductionFormula(1, 5000).subscribe((res) => {
  //     this.formula_all = res.data.data;
  //   });
  // }
  // searchFormula(search: string) {
  //   const searchval = new FormData();
  //   searchval.append('formula_name', search);
  //   this.productionService.searchProductionFormula(searchval).subscribe((res) => {
  //     this.formula_all = res.data;
  //   });
  // }

  // getAllSatff() {
  //   this.apiService.listAllStaff().subscribe((res) => {
  //     this.staffs = res.data;     
  //   });
  // }

}
