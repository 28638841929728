import { Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import { ApiService } from '../../../../../service/api.service';
import { CoreService } from '../../../../../service/core.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute  } from '@angular/router';
import { environment } from 'src/environments/environment';
import * as jsPDF from "jspdf";
import * as html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
@Component({
  selector: "app-quick-sale",
  templateUrl: "./quick-sale.component.html",
  styleUrls: ["./quick-sale.component.css"],
})



export class QuickSaleComponent implements OnInit {
  @ViewChild('content', { static: false }) content!: ElementRef;
  @ViewChildren("selPriduct") selPriduct: any;
  @ViewChildren("selPrdCode") selPrdCode: QueryList<ElementRef>;
  // @ViewChild('selPrdCode', { static: false }) selPrdCode: ElementRef;
  @ViewChildren("inputQty") inputQty: any;
  @ViewChild('prdSelecter', { static: false }) myselect: NgSelectComponent;
  @ViewChildren('closeModalNew') closeModalButtons: QueryList<ElementRef>;
  sales_settings: any;
  saleRefNo = "";
  customer: any;
  saleqt_date = <any>new Date();
  stkprd_all: any;
  srch_count: any;
  cntct_num: any;
  servicetypes: any;
  srchmsg: any;
  noCustomer: boolean;
  additional_charges:any=[];
  additional_total_amount = 0;
  ServiceAdditionaltotalAmount=0;
  edit_service_additional_charges: any;
  service_additional_charges: any[];
  doneMessage=false;
  filter_types = [
    { id: 1, name: 'Reported/Cleared'},
    { id: 2, name: 'Failed/Pending'},
    { id: 3, name: 'All'}
    
  ];
  salesItems = <any>[
    {
      salesub_prd: <any>"",
      salesub_qty: null,
      prd_code: null,
      base_qty: null,
      salesub_rate: null,
      salesub_unit_id: "",
      salesub_tax_per: null,
      prd_tax_cat_id: null,
      salesub_tax: "",
      item_desc: "",
      salesub_gd_id: "",
      prod_units: [],
      fuel_readings: {
        ob_reading:0,
        cb_reading:0,
      },
      unit: "",
      // qty: null,
      loadingImg: false,
      isSecondOptionDisabled: true,
      rate2_dsply_qty: 0,
      rate: null,
      disc_amnt: 0,
      item_disc_type: 2,
      excl_vat: 1,
      taxpercentage: null,
      itemRate: null,
      grnd_totl: null,
      base_unit_rate: null,
      item_disc: 0,
      item_bill_disc: 0,
      barcode: <any>"",
      stock_qty: 0,
      salesub_tax_amnt: 0,
      sel_rate_type: 1,
    },
  ];
  up: any;
  noPage = [];
  noRowPerPage = 14;
  ttlPage = 1;
  lastpageIteemCount = 0;
  data = [];

  downloadingIndex=-1;

  godown_all: any;
  userdefltGwn: any;
  gd_id: any;
  valErrors = <any>{};
  price_group_id = <any>1;
  item_disc_types = [
    { id: 1, name: "Rate" },
    { id: 2, name: "%" },
  ];

  vat_types = [
    { id: 1, name: "Excl. Vat" },
    { id: 2, name: "Incl. Vat" },
  ];
  taxProdCategories: any;
  barcode = null;
  barcodeloadingImg = false;
  totItemprice = <any>0;
  totItempriceWithoutBillDisc = <any>0;
  totItemDisc = <any>0;
  totItemDiscWithoutBillDisc = <any>0;
  totVatAmnt = <any>0;
  totVatAmntWithoutBillDisc = <any>0;
  sales_note = "";

  salesPayType = [
    { id: 2, name: "Cash" },
    { id: 1, name: "Credit" },
    { id: 3, name: "Bank / Card" },
    // { id: 4, name: 'Multi' }
  ];
  payTypes = ["Cash", "Credit", "Bank / Card","Both"];
  // cust_types = [
  //   { id: 1, name: 'New', disabled: false },
  //   { id: 2, name: 'Registered' }
  // ];
  sale_pay_type = <any>2;
  cashLedgers: any;
  bankLedgers: any;
  valid_till_date: any;
  cust_enable_sale_below_mrp: any;
  show_base_unit_in_print: any;
  inv_types = [
    { id: 1, name: "Tax Invoice" },
    { id: 2, name: "Simplified Tax Invoice" },
    { id: 3, name: "Invoice" },
  ];
  qt_no = "";
  vat_no = "";
  order_no = "";
  purchase_types = [
    { id: 1, name: "Standard Rated Domestic" },
    { id: 2, name: "Zero Rated Domestic" },
    { id: 3, name: "Exempt Sales" },
  ];
  zeroType = false;
  purchtypesel: any = 1;
  billDiscount = null;
  // billDiscountTtl = 0;

  display_columns = [
    { id: "prd_code", name: "Product Code", show: true },
    { id: "descp", name: "Description", show: false },
    { id: "barcode", name: "Barcode", show: false },
    { id: "item_disc", name: "Item Discount", show: false },
    { id: "tax", name: "Tax %", show: false },
    { id: "excld_vat", name: "Exclude VAT", show: false },
    { id: "item_total", name: "Item Total", show: false },
    { id: "vat_amount", name: "Vat Amount", show: true },
    { id: "grand_ttl", name: "Grand Total", show: true },
    { id: "batch", name: "Batch", show: true },
  ];
  tblSettings = {
    prd_code: true,
    descp: true,
    barcode: true,
    item_disc: true,
    tax: true,
    excld_vat: true,
    item_total: true,
    vat_amount: true,
    grand_ttl: true,
    batch: true,
  };
  prdcts = <any>[{}];
  inv_type: any;
  print_style: any;
  terms: any;
  search_type: any;
  prd_tax_cat_id: any;
  disable_sales_without_stock: any;
  enable_sale_below_mrp: any;
  sales_print_name: any = 1;
  sales_duplicate_Item = false;
  cust_id: any;
  sale_acc_ledger_id: any;
  sale_bank_ledger_id: any;
  validationMsg = "";
  pageLoadingImg = false;
  pdfLoadingImg = false;
  resultobj: any;
  cmpny: any;
  saleQtnData: any;
  is_prew_description: any;
  qr_inv: any;
  previewLoader = false;
  listing = false;
  searchLoadingImg = false;
  list_sales = [];
  curpage: any;
  lastpage: any;
  from: any;
  pgend: boolean;
  pgstart: boolean;
  base_url: string;
  savedTemplate = {
    title_text: "TAX INVOICE  فاتورة ضريبية فاتورة",
    color: "#000",
    email_color: "#000",
    font_size: "12",
    orientation: "P",
    paper: "A4-P",
    m_left: "",
    m_top: "",
    m_right: "",
    m_bottom: "",
    logo: false,
    header_type: 1,
    header_img: [],
    header_all_page: false,
    header_due: false,
    sl_no: false,
    item_desc: false,
    tax_detail: false,
    tax_type: 0,
    name_type: "N",
    footer_text: "",
    header_height: 35,
    footer_height: 32,
    page_no_display: 0,
    prnt_sign_section_display:0,
    left_h: 92.6,
    botom_h: 26.4,
    page_format: ''
  };
  logoImgUrl: string;
  tempImgUrl: string;
  footImgUrl: string;
  water_mark: string;
  hide_item_discount: any;
  hide_vat: any;
  cust_balance: 0;
  cust_bal_sign: "";
  private modalRef: NgbModalRef;
  closeResult: string;
  sales_order: any;
  sales_order_id = 0;
  customer_dt = {
    id: "",
    ledger_id: "",
    name: "",
    alias: "",
    code: "",
    email: "",
    mobile: "",
    vat_no: "",
    cntct_num:"",
    op_bal: 0,
    brch_id: 0,
    usr_type: 0,
    is_supplier: false,
    op_bal_type: true,
    add: {
      addr: "",
      zip: "",
      city: "",
      country: "",
      state: "",
      state_code: "",
      fax: "",
    },
    del: {
      addr: "",
      zip: "",
      city: "",
      country: "",
      state: "",
      state_code: "",
      fax: "",
    },
    due_day: "",
    van_line: 0,
    price_group: 1,
    note: "",
  };
  cust_resultobj: any = {};
  isSubmitInProg = false;
  userType: any;
  ord_id = null;
  usr_hide_purch_cost: any;
  only_brcd: any;
  srch_length: any;
  is_bill_disc = false;
  country_dec: string;
  rate_type = [
    { id: 1, name: "Rate1" },
    { id: 2, name: "Rate2" },
  ];

  inv_limit: any;
  inv_limit_no: any;
  current_dues: any;
  inv_due_msg: boolean;
  batchLoading = false;
  stock_qty = <any>{};
  usr_hide_others_sales_data: any;
  checkBatch = false;
  sales_agents: any;
  sale_agent: any;
  sale_vat_type: any;
  godownStocks: any;
  p_rate: any;
  lastInvoice: any;
  avg_p_rate: any;
  hist_prd_id: any;
  desktoken: any;
  user_id: any;
  showbutton: boolean;
  barcode_err: { msg: string };
  barcodeload: boolean;
  // isSecondOptionDisabled: boolean = true;
  unit_available_stock: any;
  available_stock: any;
  bs_prate: any;
  unit_bs_prate: any;
  reference: any;
  cnvert_ref_no: any;
  pageLoading = false
  no_refno: string;
  copySale: any;
  minDate:Date;
  usr_block_pre_date_select: any;
  cust_bal: any;
  cust_credit_limit: any;
  prod_alias: any;
  cur_lang: string;
  eInvoiceSupport: boolean=false;
  _tax_invoice = 'Tax Invoice';
  _simplified_tax_invoice = 'Simplified Tax Invoice';
  _invoice = 'Tax Invoice';

  // "b2BRequirements": {
  //   "isSelfBilling": false,
  //   "isThirdParty": false,
  //   "isSummaryPresent": false,
  //   "isExport": false,
  //   "isNominal": false
  // },
  // "b2CRequirements": {
  //   "isThirdParty": false,
  //   "isSummaryPresent": false,
  //   "isNominal": false
  // }
  // NNPNESB
  // - 01 for tax invoice
  // - 02 for simplified tax invoice
  // P (position 3) = 3rd Party invoice 
  // transaction, 0 for false, 1 for true
  // N (position 4) = Nominal invoice 
  // transaction, 0 for false, 1 for true
  // ubl:Invoice / ubl:Invoice / cbc:InvoiceTypeCode /@name
  // Version 1.1 40 / 57
  // E (position 5) = Exports invoice 
  // transaction, 0 for false, 1 for true
  // S (position 6) = Summary invoice 
  // transaction, 0 for false, 1 for true 
  // B (position 7) = Self billed invoice
  _Exports = 'Exports';
  _self_billing = 'Self Billing';
  _none = 'None';
  _third_party = 'Third Party';
  _nominal = 'Nominal';
  _summary = 'Summary';
  inv_sub_types = [
    { id: '00000', name: 'None' },
    { id: '10000', name: 'Third Party' },
    { id: '01000', name: 'Nominal' },
    { id: '00010', name: 'Summary' },
    { id: '00100', name: 'Exports' },
    { id: '00001', name: 'Self Billing' }
  ];
  inv_sub_type = '00000';
  xmlPath = '';
  branch_code = '';
  mode = {
    1: "production",
    2: "simulation",
    3: "development",
  }
  branch_e_invoice_enabled = 0;
  einv_simplfied_print_style = 1;
  print_style_tmp: any;
  dflt_search_methd: any;
  invoiceId: any;
  invoice_type: any;
  shop_qty: any;
  van_qty: any;
  gd_qty: any;
  branch_img_url: any;
  branchLogo: string;
  sales_order_stkprd_all: any[];
  enable_sale_below_purch: any;
  g_settings: any;
  generalSearch_type: any;
  customer_det: any;
  ledger_id: any;
  hide_prnt_alias: any;
  hide_prnt_time: any;
  hide_prnt_cust_bal: any;
  hide_prnt_pay_type: any;
  cash_amnt: any=0;
  bank_amnt: any;
  readerCalc: boolean;
  readings: any[];
  isBarcodeSearch= false;
  WtQTY= 0;
  advanceAmount = 0;
  walletBalance = 0;
  AvilableAdAmount =0;
  totalcreditCash= 0;
  BalanceAmount= 0;
  publicPath = '';
  constructor(
    private apiService: ApiService,
    private coreService: CoreService,
    private modalService: NgbModal,
    private translate: TranslateService,
    private ActiveRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.getGeneralSetting();
    this.up = this.coreService.getUserPrivilage();
    this.cmpny = this.coreService.getUserData('cmpny');
    this.branch_code = this.coreService.getUserData('branch_code');
    this.branch_img_url = this.coreService.getUserData('img_url');
    this.publicPath = (this.apiService.PHP_API_SERVER.indexOf('127.0.0.1') !== -1) ? '' : '/public';
    this.xmlPath = this.apiService.PHP_API_SERVER + this.publicPath + '/xml/' + this.cmpny + '/' + this.branch_code + '/' ;
    this.cur_lang = sessionStorage.getItem("baseLang");
this.aliasChecked()
  this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
      this.aliasChecked()
    })
    this.getAdditonalCharge();

    this.getSalesSetting();

    this.branch_e_invoice_enabled = this.coreService.getUserData('is_e_invoice');

    this.apiService.getClientSettingBykey({ key: 'enable_e_invoice' }).subscribe((res) => {
      if (res['data']) {
        this.eInvoiceSupport = ( (res['data']['cs_value'] ? true : false) && this.branch_e_invoice_enabled) ? true : false;
        this.inv_types = [
          { id: 1, name: this._tax_invoice},
          { id: 2, name: this._simplified_tax_invoice},
        ];
        if(!this.eInvoiceSupport){
          this.inv_types.push({ id: 3, name:  this._invoice });
        }
      }
    });
    this.apiService
      .getClientSettingBykey({ key: "Batch Support" })
      .subscribe((res) => {
        if (res["data"]) {
          this.checkBatch = res["data"]["cs_value"] ? true : false;
        }
      });
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.getTemplete();
    this.usr_hide_purch_cost = this.coreService.getUserData(
      "usr_hide_purch_cost"
    );
    this.usr_hide_others_sales_data = this.coreService.getUserData(
      "usr_hide_othr_sales_data"
    );
    this.user_id = this.coreService.getUserData("usr_id");
    this.usr_block_pre_date_select = this.coreService.getUserData("usr_block_pre_date_select");
    if(this.usr_block_pre_date_select){
      this.minDate= new Date();
    }else{
      this.minDate= new Date(this.coreService.getUserData("branch_open_date"));
    }
    this.langChange();
    this.apiService.getClientSettingBykey({ key: 'reader_calculator' }).subscribe((res) => {
      if (res['data']) {
        this.readerCalc = (res['data']['cs_value']) ? true : false;
      }
    });
    this.ord_id = this.ActiveRoute.snapshot.paramMap.get("ord_id");
    if (this.ord_id && !isNaN(this.ord_id)) {
      this.listing = true;
      // setTimeout(() => {
      //   alert('btn clivcked');
      //   $('#invPopup').click();
      // }, 10000);

      //   this.saleQtnData = [];

      //   var myInterval = setInterval(()=> {
      //     if(this.saleQtnData.length > 0){
      //       clearInterval(myInterval);
      //       $('#invPopup').click();
      //     }
      //  }, 500);
      this.previewSales(this.ord_id);
    }

    this.base_url = this.apiService.PHP_API_SERVER;
    this.nextRefNo();
    this.getGodownList();
    this.userdefltGwn = this.coreService.getUserData("usr_default_godown_id");
    this.userType = this.coreService.getUserData("user_type");
    this.gd_id = this.userdefltGwn ? Number(this.userdefltGwn) : 0;
    this.apiService.getAllTaxCategory().subscribe((resp) => {
      this.taxProdCategories = resp.data.filter(
        (taxCat) => Number(taxCat.is_international) == 0
      );
    });
    this.updateSalesTableSettigns();
    this.getSalesSetting();
    
    this.sale_acc_ledger_id =
      this.coreService.getUserData("usr_cash_ledger_id");
    if (!this.sale_acc_ledger_id) {
      this.apiService.getBranchCashAccount().subscribe((led_res) => {
        this.cashLedgers = led_res.data.ledger;
        this.sale_acc_ledger_id = led_res.data.taxled_ledger_id;
      });
    }
    this.cmpny = this.coreService.getUserData("cmpny");
   
    this.invoice_type=3;
    this.listQtn(1);
    // this.salesItems.push(this.defualtSalesSub);
    $("#btn1").click();
    this.country_dec = this.coreService.setDecimalLength();
    this.getAllAgents();

    this.sales_order = this.ActiveRoute.snapshot.paramMap.get('sales_odr_inv_no');
    
  

    //this.pageLoading = true

    if (this.sales_order && !isNaN(this.sales_order)) {

      

      this.sales_order_id = parseInt(this.sales_order);

    

      this.searchBankLedger('');
     
       this.listing=false;
       let searchval = new FormData();
       searchval.append("sales_odr_inv_no", this.sales_order);
       this.pageLoading = true;
       this.apiService.getSalesOrderDetails(searchval).subscribe((res: any) => {
       
      

        if(res.data){
          this.pageLoading = false;
          this.cust_id = res.data.customer;



          this.apiService.getCustomerBalance({ cust_ledger_id: res.data.customer.ledger_id }).subscribe((res) => {
            this.walletBalance = res.data.wallet;
          });

          this.advanceAmount =res.data.sales_odr_advance_amount;

         

          console.log("AdvanceAmunt");
          console.log(this.advanceAmount);

          console.log("daaaaaaaaaaaaaataaa");
          console.log(res.data);

          this.cust_id.cust_home_addr =res.data.sales_odr_cust_address;
          this.cust_id.mobile =res.data.sales_odr_cust_ph;
          // this.selectCustomer(this.cust_id);
          // this.searchCustomer(res.data.sales_odr_cust_name);
         
          this.sale_agent = res.data.sales_odr_agent_ledger_id;
          this.vat_no =res.data.sales_odr_cust_tin;
          // this.order_no=res.data.sales_odr_reference;
          this.order_no = (res.data.sales_odr_reference?res.data.sales_odr_reference:res.data.sales_odr_id);
          // console.log('this.order_no',this.order_no);
          this.cnvert_ref_no=res.data.sales_odr_branch_inv;
          
          this.sales_note =res.data.sales_odr_notes;
          // this.sale_pay_type =res.data.sales_odr_pay_type;
        
         
          if( res.data.sales_odr_pay_type == 2){
            this.sale_pay_type =3;
            this.sale_bank_ledger_id =res.data.sales_odr_acc_ledger_id;
          }
          console.log(this.sale_bank_ledger_id);
          
          if( res.data.sales_odr_pay_type == 0){
            this.sale_pay_type =2;
            this.sale_acc_ledger_id =res.data.sales_odr_acc_ledger_id;
          }
          if(res.data.sales_odr_pay_type == 1){
            this.sale_pay_type =1;
          }

          if(this.advanceAmount != 0){
            this.sale_pay_type =1;
          }
       


          var ids = res.data.sales_order_sub.map(v => (v.salesub_prd));



          this.sales_order_stkprd_all = [];
          this.no_refno=''
          this.searchStkPrdSalesOrder(ids);
        
          var myInterval = setInterval(() => {
           
            if (this.sales_order_stkprd_all.length > 0) {
              // this.qt_no =res.data.sales_odr_branch_inv;
              clearInterval(myInterval);
    
              this.searchStkPrdForEdit(res);
            }
    
          }, 100);

        }

         
       
   
       
        
       });
   
       // this.importLoader = false;
       // this.editMessage = false;
       //this.convertMessage = false;
     
        
       
   }
   this.searchCustomer('');
   this.searchStkPrd('');
  }

  aliasChecked(){
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      this.prod_alias = 'alias_true';
      
    }else{
      this.prod_alias =null;
      
    }
  }
  getAllAgents() {
    this.apiService.getAllAgents().subscribe((res) => {
      this.sales_agents = res['data'];
    });
  }
  langChange() {
    this.translate
      .get([
        "Table.prd_code",
        "Purchase.batch",
        "Table.description",
        "Table.barcode",
        "Table.item_discount",
        "Common.tax",
        "Common.Exclude_VAT",
        "Table.item_total",
        "Common.Vat_Amount",
        "Common.Grand_Total",
        "Common.standard_rated_domestic",
        "Common.zero_rated_domestic",
        "Common.exempt_sales",
      ])
      .subscribe((res: string) => {
        this.display_columns = [
          { id: "prd_code", name: res["Table.prd_code"], show: true },
          { id: "descp", name: res["Table.description"], show: false },
          { id: "barcode", name: res["Table.barcode"], show: false },
          { id: "item_disc", name: res["Table.item_discount"], show: false },
          { id: "tax", name: res["Common.tax"] + " %", show: false },
          { id: "excld_vat", name: res["Common.Exclude_VAT"], show: false },
          { id: "item_total", name: res["Table.item_total"], show: false },
          { id: "batch", name: res["Purchase.batch"], show: true },
          { id: "vat_amount", name: res["Common.Vat_Amount"], show: true },
          { id: "grand_ttl", name: res["Common.Grand_Total"], show: true },
        ];

        this.purchase_types = [
          { id: 1, name: res["Common.standard_rated_domestic"] },
          { id: 2, name: res["Common.zero_rated_domestic"] },
          { id: 3, name: res["Common.exempt_sales"] },
        ];
      });

    this.translate
      .get([
        "Common.tax_invoice",
        "Sales.excl_vat",
        "Sales.incl_vat",
        "Common.simplified_tax_invoice",
        "Common.invoice",
        'Common.none',
        'Branch.third_party',
        'Branch.nominal',
        'Branch.exports',
        'Common.summary',
        'Branch.self_billing'
      ])
      .subscribe((res: string) => {

        this._tax_invoice = res['Common.tax_invoice'];
        this._simplified_tax_invoice = res['Common.simplified_tax_invoice'];
        this._invoice = res['Common.invoice'];
        this.inv_types = [
          { id: 1, name: this._tax_invoice},
          { id: 2, name: this._simplified_tax_invoice},
        ];
        if(!this.eInvoiceSupport){
          this.inv_types.push({ id: 3, name:  this._invoice });
        }
        // this.inv_types = [
        //   { id: 1, name: res["Common.tax_invoice"] },
        //   { id: 2, name: res["Common.simplified_tax_invoice"] },
        //   { id: 3, name: res["Common.invoice"] },
        // ];

        this.vat_types = [
          { id: 1, name: res["Sales.excl_vat"] },
          { id: 2, name: res["Sales.incl_vat"] },
        ];

        this._Exports = res['Branch.exports'];
        this._self_billing = res['Branch.self_billing'] ;
        this._none = res['Common.none'];
        this._third_party = res['Branch.third_party'];
        this._nominal = res['Branch.nominal'];
        this._summary =  res['Common.summary'];
        this.inv_sub_types = [
          { id: '00000', name: this._none},
          { id: '10000', name: this._third_party },
          { id: '01000', name: this._nominal },
          { id: '00010', name: this._summary }
        ];
        if (this.inv_type == 1) {
          this.inv_sub_types.push({ id: '00100', name:  this._Exports });
          this.inv_sub_types.push({ id: '00001', name: this._self_billing});
        }
      });
  }
  ngAfterViewInit() {
    if (this.ord_id && !isNaN(this.ord_id)) {
      setTimeout(() => {
        $("#invPopup").click();
      }, 5000);
    }

    // setTimeout(() => {
    //   if(this.only_brcd){
    //     if (this.selPrdCode && this.selPrdCode.length > 0) {
    //       // Access the last element in the QueryList and focus on it
    //       this.selPrdCode.last.nativeElement.focus();
    //     }
    //   }else{
    //     if (this.selPriduct && this.selPriduct.length > 0) {
    //       // Access the last element in the QueryList and focus on it
    //       this.selPriduct.last.focus();
    //     }
    //   }

    // }, 500);
  }
  validateAndSubmit() {
    this.isSubmitInProg = true;
    let callFunction = "createCustOrSup";
    if (this.userType == "ADMIN") {
      this.customer_dt.usr_type = 1;
    } else {
      this.customer_dt.usr_type = 0;
    }

    this.apiService[callFunction](this.customer_dt).subscribe((res) => {
      // console.log(this.staff);
      this.isSubmitInProg = false;
      if (res.error != null) {
        this.cust_resultobj = res.error;
      } else {
        this.cust_resultobj = {};
        this.modalRef.close();
        this.clearCustForm();
      }
    });
  }
  clearCustForm() {
    this.customer_dt = {
      id: "",
      ledger_id: "",
      name: "",
      alias: "",
      code: "",
      email: "",
      mobile: "",
      vat_no: "",
      cntct_num:"",
      op_bal: 0,
      brch_id: 0,
      usr_type: 0,
      is_supplier: false,
      op_bal_type: true,
      add: {
        addr: "",
        zip: "",
        city: "",
        country: "",
        state: "",
        state_code: "",
        fax: "",
      },
      del: {
        addr: "",
        zip: "",
        city: "",
        country: "",
        state: "",
        state_code: "",
        fax: "",
      },
      due_day: "",
      van_line: 0,
      price_group: 1,
      note: "",
    };
  }
  getTemplete() {
    this.apiService.getSettings().subscribe((res) => {
      this.savedTemplate = {
        title_text: res.data.prnt_title_text,
        color: "#000",
        email_color: "#000",
        font_size: "12",
        orientation: "P",
        paper: "A4-P",
        m_left: "",
        m_top: "",
        m_right: "",
        m_bottom: "",
        logo: res.data.prnt_header_logo,
        header_type: res.data.prnt_header_type,
        header_img: [],
        header_all_page: false,
        header_due: false,
        sl_no: false,
        item_desc: false,
        tax_detail: false,
        tax_type: 0,
        name_type: "N",
        footer_text: res.data.prnt_display_footer_text,
        header_height: res.data.prnt_nt_header_height,
        footer_height: res.data.prnt_nt_footer_height,
        page_no_display: res.data.prnt_nt_display_page_no,
        prnt_sign_section_display : res.data.prnt_sign_section_display, 
        left_h: res.data.prnt_nt_left_space,
        botom_h: res.data.prnt_nt_bottom_space,
        page_format: res.data.prnt_nt_page_no_disply_frmt
      };
      if (res.data.prnt_header_logo) {
        this.logoImgUrl =
          this.apiService.PHP_API_SERVER +
          "/" +
          res.data.prnt_header_logo_img_path;
      } else {
        this.logoImgUrl = "";
      }

      if (
        res.data.prnt_header_type == 2 ||
        this.print_style == 6 ||
        this.print_style == 7
      ) {
        this.tempImgUrl =
          this.apiService.PHP_API_SERVER + "/" + res.data.prnt_header_img_path;
        this.footImgUrl =
          this.apiService.PHP_API_SERVER + "/" + res.data.prnt_footer_img_path;
      } else {
        this.tempImgUrl = "";
        this.footImgUrl = "";
      }

      this.branchLogo = this.branch_img_url ;
      this.hide_prnt_alias = res.data.prnt_show_alias;
      this.hide_prnt_time = res.data.prnt_show_time;
      this.hide_prnt_cust_bal = res.data.prnt_cust_show_bal;
      this.hide_prnt_pay_type = res.data.prnt_show_pay_type;
      

      if(res.data.water_mark_img_path){
        this.water_mark = this.apiService.PHP_API_SERVER + "/" + res.data.water_mark_img_path;
      } else {
        this.water_mark = "";
      }

      this.hide_item_discount = res.data.hide_item_discount;
      this.hide_vat = res.data.hide_vat;
      
    });
  }
  openModal(content: any, size) {
    this.modalRef = this.modalService.open(content, {
      size: size,
      backdrop: "static",
    });
    this.modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  getSalesSetting() {
    this.apiService.getSalesSetting().subscribe((res) => {
      if (res["data"]) {
        this.sales_settings = res["data"];
        this.inv_type = this.sales_settings.ss_inv_type;
        this.print_style = this.sales_settings.print_style;
        this.print_style_tmp = this.sales_settings.print_style;
        this.einv_simplfied_print_style = this.sales_settings.einv_simplfied_print_style;
        this.terms = this.sales_settings.sales_default_terms;
        this.search_type = this.sales_settings.search_type;
        this.only_brcd = this.sales_settings.search_with_only_barcod;
        this.dflt_search_methd=this.sales_settings.dflt_search_methd;

        if (this.inv_type == 3) {
          this.purchtypesel = 2;
          this.prd_tax_cat_id = 3;
          this.selectTaxType(2);
        }
        this.disable_sales_without_stock =
          this.sales_settings.disable_sales_without_stock;
        this.enable_sale_below_mrp = this.sales_settings.enable_sale_below_mrp;
        this.enable_sale_below_purch = this.sales_settings.enable_sale_below_purch;
        this.show_base_unit_in_print =
          this.sales_settings.show_base_unit_in_print;
        this.sales_print_name = this.sales_settings.sales_print_name;
        this.sales_duplicate_Item = this.sales_settings.sales_duplicate_item;
        this.inv_limit = this.sales_settings.inv_due_limit;
        this.sale_vat_type = this.sales_settings.vat_type;


      } else {
        this.disable_sales_without_stock = 0;
        this.enable_sale_below_mrp = 0;
        this.enable_sale_below_purch =0;
        this.show_base_unit_in_print = 0;
        this.inv_type = 1;
        this.sales_print_name = 1;
        this.print_style = 1;
        this.print_style_tmp = 1;
        this.terms = "";
        this.search_type = 1;
      }
      if(this.sales_settings.ss_default_pay_type==2){
        this.sale_pay_type=3;
       }else if(this.sales_settings.ss_default_pay_type==3){
        this.sale_pay_type=4;
       }else{
        this.sale_pay_type=2;
       }

      setTimeout(() => {
        if (this.only_brcd) {
          if (this.selPrdCode && this.selPrdCode.length > 0) {
            // Access the last element in the QueryList and focus on it
            this.selPrdCode.last.nativeElement.focus();
          }
        } else {
          if (this.selPriduct && this.selPriduct.length > 0) {
            // Access the last element in the QueryList and focus on it
            this.selPriduct.last.focus();
          }
        }
      }, 500);


      setTimeout(() => {
        if (this.dflt_search_methd) {
          if (this.selPriduct && this.selPriduct.length > 0) {

            this.selPriduct.last.nativeElement.focus();
          }
        } else {
          if (this.selPrdCode && this.selPrdCode.length > 0) {
            this.selPrdCode.last.nativeElement.focus();
          }
        }
      }, 500);

    });
  }

  updatePayment(){

    console.log("this.inv_type");
    console.log(this.inv_type);
    this.inv_sub_types = [
      { id: '00000', name: this._none},
      { id: '10000', name: this._third_party },
      { id: '01000', name: this._nominal },
      { id: '00010', name: this._summary }
    ];
    if (this.inv_type == 1) {
      this.inv_sub_types.push({ id: '00100', name:  this._Exports });
      this.inv_sub_types.push({ id: '00001', name: this._self_billing});
    }
    if (this.inv_type == 2) {
      this.sale_pay_type = 2;
    } else {
      this.sale_pay_type = 1;
    }

    if (this.inv_type == 3) {
      this.purchtypesel = 2;
      this.prd_tax_cat_id = 3;
      this.selectTaxType(2);
    }
  }

  updateSalesTableSettigns() {
    let tabeleSett = this.coreService.getLocalStorage("tds");
    let display_columns = [];
    if (tabeleSett) {
      display_columns = tabeleSett;
    } else {
      display_columns = this.display_columns;
    }
   
    display_columns.forEach((val) => {
      this.tblSettings[val.id] = !val.show;
    });
   
  }

  updateTableSettings() {
    this.coreService.setLocalStorage(
      "tds",
      JSON.stringify(this.display_columns)
    );
    this.updateSalesTableSettigns();
  }

  searchCashLedger(search: string) {
    const searchval = new FormData();
    searchval.append("ledger_name", search);
    searchval.append("ledger_grp", "3");

    this.apiService.searchLedgerByAccGroup(searchval).subscribe((res) => {
      this.cashLedgers = res["data"];
    });
  }

  searchBankLedger(search: string) {
    const searchval = new FormData();
    searchval.append("ledger_name", search);
    searchval.append("ledger_grp", "4");
    this.apiService.searchLedgerByAccGroup(searchval).subscribe((res) => {
      this.bankLedgers = res["data"];
    });
  }

  addNewRow(i) {
    // $('#pop-upclose').click();
   
  
      if (this.validateSelectedGodown()) {

        // Duplicate item validation    
        const status = this.salesItems.some(user => {
          let counter  = 0;
          for (const iterator of this.salesItems) {
            if (iterator.salesub_prd_id === user.salesub_prd_id && iterator.salesub_unit_id === user.salesub_unit_id && iterator.salesub_rate === user.salesub_rate) {
              counter += 1;
            }
          }
          
          return counter > 1;
        });
        if(this.sales_settings){
          if(this.sales_settings.sales_item_grid_warning ){
            if(status){
              let pname=i?this.salesItems[i].salesub_prd.prd_name:'Item';
              
               if(this.sales_settings.sales_item_grid_warning==1){
                   alert( pname+" Already Exist in this List");
                 }else if(this.sales_settings.sales_item_grid_warning==2){
                   alert(pname +" Already Exist in this List,You Cannot Add");
                   return false;
                 }
            }
             
          }
        }
       

        this.salesItems.push({
          salesub_prd: "",
          prd_code: "",
          salesub_qty: null,
          base_qty: null,
          salesub_rate: null,
          salesub_unit_id: "",
          salesub_tax_per: null,
          prd_tax_cat_id: null,
          salesub_tax: "",
          item_desc: "",
          salesub_gd_id: "",
          prod_units: [],
          fuel_readings: {
            ob_reading:0,
            cb_reading:0,
          },
          unit: "",
          // qty: null,
          loadingImg: false,
          isSecondOptionDisabled: true,
          rate2_dsply_qty: 0,
          rate: null,
          rate_org: null,
          disc_amnt: 0,
          item_disc_type: null,
          excl_vat: this.sale_vat_type ? 2 : 1,
          taxpercentage: null,
          itemRate: null,
          grnd_totl: null,
          base_unit_rate: null,
          item_disc: 0,
          item_bill_disc: 0,
          barcode: "",
          stock_qty: 0,
          salesub_tax_amnt: 0,
          sel_rate_type: 1,
        });
  
        if (this.only_brcd) {
          setTimeout(() => {
            if (this.selPrdCode && this.selPrdCode.length > 0) {
              // Access the last element in the QueryList and focus on it
              this.selPrdCode.last.nativeElement.focus();
            }
          }, 500);
        } else {
          setTimeout(() => {
            if (this.selPriduct && this.selPriduct.length > 0) {
              // Access the last element in the QueryList and focus on it
              this.selPriduct.last.focus();
            }
          }, 500);
        }
        
        setTimeout(() => {
          if (this.dflt_search_methd) {
            if (this.selPriduct && this.selPriduct.length > 0) {
  
              this.selPriduct.last.nativeElement.focus();
            }
          } else {
            if (this.selPrdCode && this.selPrdCode.length > 0) {
              this.selPrdCode.last.nativeElement.focus();
            }
          }
        }, 500);
      }
   
  }
  remove(index) {
    this.salesItems.splice(index, 1);
    delete this.valErrors["rate_" + index];
    this.sumSaleTotal();
  }
  nextRefNo() {
    let searchval = new FormData();
    this.apiService.getSaleRefNo(searchval).subscribe((res) => {
      this.saleRefNo = res["data"].sale_num;
      this.qt_no = res["data"].sq_branch_qt_no;
    });
  }

  diffqty(op_rd=0,cls_rd=0,i){
   

    if(Number(cls_rd)&&Number(op_rd)&&this.salesItems[i].salesub_prd){
      this.salesItems[i].salesub_qty=Number(cls_rd)-Number(op_rd);
      this.readings=[];
      this.readings.push(
        {
          ob_reading: op_rd,
          cb_reading: cls_rd,
        }
      );
      this.salesItems[i].fuel_readings=this.readings;
      this.calcRates(i);
    }
    this.closeModalByIndex(i);
  }
  closeModalByIndex(i: number) {
    const buttonsArray = this.closeModalButtons.toArray();
    if (buttonsArray[i]) {
      buttonsArray[i].nativeElement.click();
    }
  }

  searchCustomer(search: string) {
    const searchval = new FormData();
    searchval.append("cust_name", search);
    if(this.eInvoiceSupport){
      searchval.append('einvoice_details', '1');
    }
    if(this.translate.currentLang != 'English'){
      searchval.append('with-alias','1'); 
    }
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res["data"];
    });
  }
  searchCustomeralias(search: string){
    const searchval = new FormData();
    searchval.append('cust_name', search);
    searchval.append('with-alias','1'); 
    // if(this.eInvoiceSupport){
    //   searchval.append('einvoice', '1');
    // }
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
    });

  }

  selectCustomer(cust) {

    if (cust &&cust.ledger_id) {
      // this.cust_balance =0;
      // this.cust_bal_sign = '';
      const searchval = new FormData();
      searchval.append('ledger_id', cust.ledger_id);
      this.apiService.getCustDetails(searchval).subscribe((res) => {
        this.customer_det = res.data;
        if(res.data){
          this.cntct_num = cust.mobile;
          this.cust_balance = this.customer_det.balance.bal_without_sign;
          this.cust_bal_sign = this.customer_det.balance.bal_sign;
          this.current_dues = this.customer_det.crd_limit;
          this.cust_bal = this.customer_det.balance.bal_amt;
        }else{
          this.cust_balance = 0;
          this.cust_bal_sign = '';
        }
      });
      this.noCustomer = false;
      this.vat_no = this.eInvoiceSupport ? cust.einv_vat_no : cust.vat_no;
      if (cust.price_group_id) {
        this.price_group_id = cust.price_group_id;
      }
    
      let date = new Date(this.saleqt_date);
      if (cust.due_days) {
        date.setDate(date.getDate() + Number(cust.due_days));
      } else {
        date.setDate(date.getDate() + 30);
      }

      this.valid_till_date = new Date(date);

      this.cust_enable_sale_below_mrp = cust.enable_sale_below_mrp;
      this.inv_limit_no = cust.inv_due_limit_no;   
      this.sale_agent= cust.default_sales_agent;
      
      this.cust_credit_limit = cust.cust_credit_limit;
      // payment option by settings
     if(this.sales_settings.ss_default_pay_type==1){
      this.sale_pay_type=1;
     }else if(this.sales_settings.ss_default_pay_type==2){
      this.sale_pay_type=3;
     }else if(this.sales_settings.ss_default_pay_type==3){
      this.sale_pay_type=4;
     }else{
      this.sale_pay_type=2;
     }
    } else {
      this.vat_no = '';
      this.cust_balance = 0;
      this.cust_bal_sign = "";
      this.cntct_num='';
      this.cust_credit_limit=0;
      this.cust_bal=0;
      // remove customer after selecting payment type credit, change payment type to cash
      if (this.sale_pay_type == 1) {
        this.sale_pay_type = 2;
      }
    }
  }
  
  // do not use this api other than sales bcz add some exeption in this (by ajmal)
  searchStkPrd(search: string) {
    let searchval = new FormData();
    searchval.append("keyword", search);
    searchval.append("stk_stat", "1");
    searchval.append("godown_id", this.gd_id);
    if(this.prod_alias){
      searchval.append('alias_search', '1');
    }
    this.apiService.getSalesStockbyItem(searchval).subscribe((res) => {
      this.stkprd_all = res["data"];
      this.srch_count = this.stkprd_all.length;
    });
  }

  changePay(paytype){
    this.cash_amnt=0;
    if(paytype==4){
      this.cash_amnt=this.totItemprice - this.totItemDisc + this.totVatAmnt + this.ServiceAdditionaltotalAmount;
      this.bank_amnt=0;
    }
  }
  cashAmntchng(amnt){
    this.bank_amnt=(this.totItemprice - this.totItemDisc + this.totVatAmnt + this.ServiceAdditionaltotalAmount)-amnt;
  }

  bankAmntchng(amnt){
    this.cash_amnt=(this.totItemprice - this.totItemDisc + this.totVatAmnt + this.ServiceAdditionaltotalAmount)-amnt;

  }



  getGodownList() {
    let searchval = new FormData();
    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godown_all = res["data"];
    });
  }

  selectedProduct(i, isBracodeSearch = false) {
    if (this.salesItems[i].salesub_prd && this.validateSelectedGodown()) {
      // if(this.validateSelectedGodown()){
      this.getStockDetails(i, isBracodeSearch);
    } else {
      this.salesItems[i] = {
        salesub_prd: "",
        prd_code: "",
        salesub_qty: null,
        base_qty: null,
        salesub_rate: null,
        salesub_unit_id: "",
        salesub_tax_per: null,
        prd_tax_cat_id: null,
        salesub_tax: "",
        item_desc: "",
        salesub_gd_id: "",
        prod_units: [],
        fuel_readings: {
          ob_reading:0,
          cb_reading:0,
        },
        unit: "",
        // qty: null,
        loadingImg: false,
        isSecondOptionDisabled: true,
        rate2_dsply_qty: 0,
        rate: null,
        rate_org: null,
        disc_amnt: 0,
        item_disc_type: null,
        excl_vat: this.sale_vat_type ? 2 : 1,
        taxpercentage: null,
        itemRate: null,
        grnd_totl: null,
        base_unit_rate: null,
        item_disc: 0,
        item_bill_disc: 0,
        barcode: "",
        salesub_tax_amnt: 0,
        sel_rate_type: 1,
      };
    }
  }

  updateTaxPerc(i) {
    const selTax = this.taxProdCategories.filter(
      (taxCat) => taxCat.taxcat_id === this.salesItems[i].prd_tax_cat_id
    );
    this.salesItems[i].salesub_tax_per = selTax[0].taxcat_tax_per;
    this.calcRates(i);
  }

  searchStkPrdBarcode() {
    if (this.validateBarcode() && this.validateSelectedGodown()) {
      let searchval = new FormData();
      searchval.append("barcode", this.barcode);
      this.barcodeloadingImg = true;
      this.apiService.getStockbyBarcodeSales(searchval).subscribe((res) => {
        if (res.data) {
          // $('#pop-up').click();
          // console.log(res['data']);

          this.isBarcodeSearch =true;

          this.WtQTY =Number(res.data.Qty);
          if (!this.sales_duplicate_Item) {
            var existArrayInx = this.salesItems.findIndex(
              (x) =>
                x.salesub_prd.prd_id === res.data.prd_id &&
                (x.item_desc == "" || x.item_desc == null) &&
                x.salesub_unit_id === res.data.produnit_unit_id &&
                x.rate === res.data.bs_srate
            );
          }

          if (!this.sales_duplicate_Item && existArrayInx != -1) {
            // console.log('item already exits');
            // if(this.isBarcodeSearch){
            //   this.salesItems[existArrayInx].salesub_qty += Number(this.WtQTY);
            // }
            // else{
            //   this.salesItems[existArrayInx].salesub_qty += 1;
            // }
            this.salesItems[existArrayInx].salesub_qty =  Number(this.salesItems[existArrayInx].salesub_qty);
             this.salesItems[existArrayInx].salesub_qty += this.isBarcodeSearch ? Number(this.WtQTY) : 1;
            
            this.calcRates(existArrayInx);
          } else {
            // do not add new row, if last row not selected any item
            if (this.salesItems[this.salesItems.length - 1].salesub_prd) {
              this.addNewRow('');
            }
            let lastRowIndex = this.salesItems.length - 1;
            this.salesItems[lastRowIndex].salesub_prd = res["data"];
            this.selectedProduct(lastRowIndex, true);
          }
          
        } else {
          this.valErrors.barcode = { msg: "No product found" };
        }
        this.barcode = "";
        this.barcodeloadingImg = false;
        // $('#pop-up').click();
        $("#btn2").click();
      });
    }
  }

  // do not use this api other than sales bcz add some exeption in this (by ajmal)

  searchPrdCode(search: any) {
    const searchval = new FormData();
    searchval.append("prd_cod", search);
    searchval.append("godown_id", this.gd_id);
    this.barcodeload = true;
    this.apiService.searchByPrdode(searchval).subscribe((res) => {
      if (res.data) {
        // $('#pop-up').click();
        // console.log(res['data']);
        this.barcodeload = false;
        this.WtQTY =Number(res.data.Qty);
        if (!this.sales_duplicate_Item) {
          var existArrayInx = this.salesItems.findIndex(
            (x) =>
              x.salesub_prd.prd_id === res.data.prd_id &&
              (x.item_desc == "" || x.item_desc == null) &&
              x.salesub_unit_id === res.data.produnit_unit_id &&
              x.rate === res.data.bs_srate
          );
        }

        if (!this.sales_duplicate_Item && existArrayInx != -1) {
          // console.log('item already exits');
         
          this.salesItems[existArrayInx].salesub_qty =  Number(this.salesItems[existArrayInx].salesub_qty);
          this.salesItems[existArrayInx].salesub_qty += this.isBarcodeSearch ? Number(this.WtQTY) : 1;


          
          this.calcRates(existArrayInx);
        } else {
          // do not add new row, if last row not selected any item
          if (this.salesItems[this.salesItems.length - 1].salesub_prd) {
            this.addNewRow('');
          }
          let lastRowIndex = this.salesItems.length - 1;
          this.salesItems[lastRowIndex].salesub_prd = res["data"];
          this.selectedProduct(lastRowIndex, true);
        }

        setTimeout(() => {
          $(".inputQty").focus();
          $(".inputQty").select();
        }, 500);
        this.isBarcodeSearch = true;
      } else {
        this.barcode_err = { msg: "No product found" };
      }
      this.barcode = "";
      
    });
  }
  Select_invoiceType(id){

   
    if (id){
      this.invoiceId=id;
      this.searchLoadingImg = true;
      let searchval = new FormData();
      searchval.append("invoiceType_id",this.invoiceId);
      this.apiService.getSalesInvList(searchval, 1).subscribe((res) => {
        this.list_sales = res.data.data;
        this.curpage = res.data["current_page"];
        this.lastpage = res.data["last_page"];
        this.from = res.data["from"];
        this.pgEnd(this.curpage, this.lastpage);
        this.pgStart(this.curpage);
        this.searchLoadingImg = false;
      });

    }
  }

  getStockDetails(i, isBracodeSearch) {
    this.salesItems[i].sb_id=0;
    this.salesItems[i].loadingImg = true;
    let prd_id = this.salesItems[i].salesub_prd.prd_id;
    const searchval = new FormData();
    searchval.append("prd_id", prd_id);
    searchval.append("gd_id", this.gd_id);
    searchval.append("price_group_id", this.price_group_id);
    this.apiService.getsaleProdDetails(searchval).subscribe((res) => {
      let selProdInfo = res["data"];
      this.salesItems[i].salesub_qty = 1;
       if(this.isBarcodeSearch){
        this.salesItems[i].salesub_qty = this.WtQTY;
       }
     
    
      this.salesItems[i].prod_units = selProdInfo.prod_units;
      this.salesItems[i].prd_code = selProdInfo.prd_code;

      this.salesItems[i].loadingImg = false;
      this.salesItems[i].prd_tax_cat_id = selProdInfo.prd_tax_cat_id;
      this.salesItems[i].salesub_tax_per = selProdInfo.prd_tax;
      this.salesItems[i].base_unit_rate = selProdInfo.base_unit_rate;
      this.salesItems[i].barcode = selProdInfo.prd_barcode;
      this.salesItems[i].item_desc = selProdInfo.prd_desc;
      this.salesItems[i].item_disc_type = 1;
      this.salesItems[i].salesub_gd_id = this.gd_id;
      this.salesItems[i].salesub_prd_id = selProdInfo.prd_id;
      this.salesItems[i].rate2_dsply_qty = selProdInfo.bs_rate2_display_qty;
      this.salesItems[i].batches = selProdInfo.batches;
      this.salesItems[i].excl_vat = this.sale_vat_type ? 2 : 1;
      // salesub_unit_id
      // current stock of product, actully chk on the time of save for accurate result
      this.stock_qty[i] = selProdInfo.stock_qty;
      this.salesItems[i].stock_qty = selProdInfo.stock_qty;
      this.salesItems[i].prd_location = selProdInfo.pl_location;
      this.salesItems[i].p_rate = selProdInfo.bs_prate;
      this.salesItems[i].unit_available_stock = selProdInfo.stock_qty;
      this.salesItems[i].unit_available_stock_in_br = selProdInfo.bs_stock_quantity;
      this.salesItems[i].available_stock = selProdInfo.stock_qty;
      this.bs_prate = selProdInfo.bs_prate;
      this.unit_bs_prate = selProdInfo.bs_prate;
      this.shop_qty = selProdInfo.bs_stock_quantity_shop;
      this.van_qty = selProdInfo.bs_stock_quantity_van;
      this.gd_qty = selProdInfo.bs_stock_quantity_gd;
      
      // console.log('selProdInfo.fuel_readings');
      // console.log(selProdInfo);
      
      if(selProdInfo.fuel_reading){
        this.salesItems[i].fuel_readings.ob_reading=selProdInfo.fuel_reading.cb_reading;
        this.salesItems[i].fuel_readings.cb_reading=0;
      }
      if(selProdInfo.batches.length > 0 && this.checkBatch){
        this.salesItems[i].sb_id =selProdInfo.batches[0].sb_id;

        if (this.salesItems[i].sb_id) {
          // this.selctedProd.stock_qty = 333
          // getGodownBatchStock(){}
          const searchval = new FormData();
          searchval.append("prd_id", this.salesItems[i].salesub_prd.prd_id);
          searchval.append("gd_id", this.gd_id);
          searchval.append("price_group_id", this.price_group_id);
          searchval.append("batch_id", this.salesItems[i].sb_id);
          this.batchLoading = true;
          this.apiService.getGodownBatchStock(searchval).subscribe((resp) => {
            this.batchLoading = false;
    
            // console.log("gd details");
            // console.log(resp['data']['gdStock']);
    
            // console.log("batch Details");
            // console.log(resp['data']['prod_units']);
    
            console.log("resp['data']");
            console.log(resp["data"]);
            // this.salesItems[i].stock_qty = resp['data']['gdStock'].gbs_qty || 0;
            // this.salesItems[i].prod_units =resp['data']['prod_units'];
    
            this.salesItems[i].unit_available_stock =
              resp["data"]["gdStock"].gbs_qty || 0;
            this.selectedUnit(i);
          });
        } 

      }
      // console.log(this.salesItems);
      // this.selctedProd = res['data'];
      if (!isBracodeSearch && selProdInfo.prd_default_unit_id) {
        this.salesItems[i].salesub_unit_id = selProdInfo.prd_default_unit_id;
      } else {
        this.salesItems[i].salesub_unit_id = this.salesItems[i].salesub_prd
          .produnit_unit_id
          ? this.salesItems[i].salesub_prd.produnit_unit_id
          : selProdInfo.prd_base_unit_id;
      }
      // console.log(this.salesItems[i].salesub_unit_id);

      // $('.modal-backdrop').remove();
      this.getGodownWiseStock(prd_id);
      if (this.cust_id) {
        this.getLastSaleToCust(prd_id, this.cust_id.cust_id);
      }
      // this.barcode = this.selctedProd.prd_barcode;
      this.selectedUnit(i);
      if (!isBracodeSearch) {
        setTimeout(() => {
          $(".inputQty").focus();
          $(".inputQty").select();
          $("#btn2").click();
        }, 500);
      }
      this.isBarcodeSearch =false;
    });
  }

  updateStock(i) {
    if (this.salesItems[i].sb_id) {
      // this.selctedProd.stock_qty = 333
      // getGodownBatchStock(){}
      const searchval = new FormData();
      searchval.append("prd_id", this.salesItems[i].salesub_prd.prd_id);
      searchval.append("gd_id", this.gd_id);
      searchval.append("price_group_id", this.price_group_id);
      searchval.append("batch_id", this.salesItems[i].sb_id);
      this.batchLoading = true;
      this.apiService.getGodownBatchStock(searchval).subscribe((resp) => {
        this.batchLoading = false;

        // console.log("gd details");
        // console.log(resp['data']['gdStock']);

        // console.log("batch Details");
        // console.log(resp['data']['prod_units']);

        console.log("resp['data']");
        console.log(resp["data"]);
        // this.salesItems[i].stock_qty = resp['data']['gdStock'].gbs_qty || 0;
        if(this.price_group_id == 1){
         this.salesItems[i].prod_units =resp['data']['prod_units'];
         this.calcRates(i);
        }
        this.salesItems[i].unit_available_stock =
          resp["data"]["gdStock"].gbs_qty || 0;
        this.selectedUnit(i);
      });
    } else {
      // this.salesItems[i].stock_qty = this.stock_qty[i];
      this.salesItems[i].unit_available_stock = this.salesItems[i].stock_qty;
    }
  }

  focusRateInput(event) {
    $(".inputRate").focus();
    $(".inputRate").select();
    return false;
  }

  selectedUnit(i) {
    const prdUnts = this.salesItems[i].prod_units.filter(
      (ProdUnits) =>
        ProdUnits.produnit_unit_id === this.salesItems[i].salesub_unit_id
    );
    var unit_rate =
      this.salesItems[i].sel_rate_type == 1
        ? prdUnts[0].sur_unit_rate
        : prdUnts[0].sur_unit_rate2;
    this.salesItems[i].rate = unit_rate
      ? unit_rate
      : prdUnts[0].unit_base_qty * this.salesItems[i].base_unit_rate;

    this.salesItems[i].rate_org = unit_rate
      ? unit_rate
      : prdUnts[0].unit_base_qty * this.salesItems[i].base_unit_rate;

    this.salesItems[i].base_qty = prdUnts[0].unit_base_qty;

    this.salesItems[i].unit_bs_prate =
      this.bs_prate * this.salesItems[i].base_qty;

    if (this.salesItems[i].sb_id) {
      const searchval = new FormData();
      searchval.append("prd_id", this.salesItems[i].salesub_prd.prd_id);
      searchval.append("gd_id", this.gd_id);
      searchval.append("price_group_id", this.price_group_id);
      searchval.append("batch_id", this.salesItems[i].sb_id);
      this.batchLoading = true;
      this.apiService.getGodownBatchStock(searchval).subscribe((resp) => {
        this.batchLoading = false;

        this.salesItems[i].unit_available_stock =
          resp["data"]["gdStock"].gbs_qty / this.salesItems[i].base_qty || 0;
        // this.selectedUnit(i);
      });
    } else {
      this.salesItems[i].unit_available_stock =
        this.salesItems[i].available_stock / this.salesItems[i].base_qty;
    }

    this.calcRates(i);
  }

  calcRates(i, isBillDiscount = false) {
    if (!isBillDiscount) {
      this.billDiscount = 0;
      this.salesItems[i].item_bill_disc = 0;
    }

    let qty = this.salesItems[i].salesub_qty
      ? this.salesItems[i].salesub_qty
      : 1;
    let qty1 = this.salesItems[i].salesub_qty * this.salesItems[i].base_qty;

    if (qty1 >= this.salesItems[i].rate2_dsply_qty) {
      this.salesItems[i].isSecondOptionDisabled = false;
    } else {
      this.salesItems[i].sel_rate_type = 1;
      this.salesItems[i].isSecondOptionDisabled = true;
      // this.selectedUnit(i);
    }
    let rate = this.salesItems[i].rate ? Number(this.salesItems[i].rate) : 0;
    let itemDisc = this.salesItems[i].disc_amnt
      ? this.salesItems[i].disc_amnt
      : 0;

    let purchAmnt = qty * rate;

    if (this.salesItems[i].item_disc_type != 1) {
      itemDisc = (purchAmnt * itemDisc) / 100;
    }

    let itemDiscWithoutBillDisc = itemDisc;
    itemDisc = Number(itemDisc) + Number(this.salesItems[i].item_bill_disc);
    this.salesItems[i].item_disc = itemDisc;

    // console.log('item disc ' + itemDiscWithoutBillDisc);
    // console.log('item disc + bill disc ' + itemDisc);
    // let rateWithoutBillDisc = rate;
    let itemTotal = 0;
    let itemTotalWithoutBillDisc = 0;
    let rateWithoutBillDisc = rate;
    //tax zero type
    if (this.zeroType) {
      this.salesItems[i].salesub_tax_amnt = 0;
      this.salesItems[i].salesub_tax_amnt_WithoutBillDisc = 0;
      itemTotal = purchAmnt;
      itemTotalWithoutBillDisc = purchAmnt;
    } else {
      if (this.salesItems[i].excl_vat == 1) {
        this.salesItems[i].salesub_tax_amnt =
          ((purchAmnt - itemDisc) * this.salesItems[i].salesub_tax_per) / 100;
        this.salesItems[i].salesub_tax_amnt_WithoutBillDisc =
          ((purchAmnt - itemDiscWithoutBillDisc) *
            this.salesItems[i].salesub_tax_per) /
          100;
        itemTotal = purchAmnt + this.salesItems[i].salesub_tax_amnt;
        itemTotalWithoutBillDisc =
          purchAmnt + this.salesItems[i].salesub_tax_amnt_WithoutBillDisc;
      } else {
        this.salesItems[i].salesub_tax_amnt =
          purchAmnt -
          itemDisc -
          ((purchAmnt - itemDisc) * 100) /
            (this.salesItems[i].salesub_tax_per + 100);
        this.salesItems[i].salesub_tax_amnt_WithoutBillDisc =
          purchAmnt -
          itemDiscWithoutBillDisc -
          ((purchAmnt - itemDiscWithoutBillDisc) * 100) /
            (this.salesItems[i].salesub_tax_per + 100);
        // rateWithoutBillDisc = (((rate - itemDiscWithoutBillDisc) * 100) / (this.salesItems[i].salesub_tax_per + 100));
        rate = (purchAmnt - this.salesItems[i].salesub_tax_amnt) / qty;
        rateWithoutBillDisc =
          (purchAmnt - this.salesItems[i].salesub_tax_amnt_WithoutBillDisc) /
          qty;
        // this.itemRate = (this.purchAmnt-this.tax_amnt) / this.qty;
        itemTotal = purchAmnt;
        itemTotalWithoutBillDisc = purchAmnt;
      }
    }
    // console.log('rate ' + rate);
    this.salesItems[i].salesub_tax = this.salesItems[i].salesub_tax_amnt / qty;
    // let itemDiscWithoutBillDisc = itemDisc;
    this.salesItems[i].item_disc_WithoutBillDisc = itemDiscWithoutBillDisc;

    this.salesItems[i].salesub_rate = rate;
    this.salesItems[i].item_total = rate * qty - itemDisc;
    this.salesItems[i].salesub_rate_without_bill_disc = rateWithoutBillDisc;

    this.salesItems[i].grnd_totl = itemTotal - itemDisc;
    this.salesItems[i].grnd_totl_WithoutBillDisc =
      itemTotalWithoutBillDisc - itemDiscWithoutBillDisc;

    this.sumSaleTotal();
  }
 

  sumSaleTotal() {
    this.totItemprice = 0;
    this.totItempriceWithoutBillDisc = 0;
    this.totItemDisc = 0;
    this.totItemDiscWithoutBillDisc = 0;
    this.totVatAmnt = 0;
    this.totVatAmntWithoutBillDisc = 0;
    // this.billDiscountTtl = 0;

    for (var i = 0; i < this.salesItems.length; i++) {
      // console.log('sub_disc ' + this.salesItems[i]['item_disc']);
      this.totItemprice =
        Number(this.totItemprice) +
        Number(this.salesItems[i]["salesub_qty"]) *
          Number(this.salesItems[i]["salesub_rate"]);
      this.totItempriceWithoutBillDisc =
        Number(this.totItempriceWithoutBillDisc) +
        Number(this.salesItems[i]["salesub_qty"]) *
          Number(this.salesItems[i]["salesub_rate_without_bill_disc"]);
      this.totItemDisc =
        Number(this.totItemDisc) + Number(this.salesItems[i]["item_disc"]);
      this.totItemDiscWithoutBillDisc =
        Number(this.totItemDiscWithoutBillDisc) +
        Number(this.salesItems[i]["item_disc_WithoutBillDisc"]);
      this.totVatAmnt =
        Number(this.totVatAmnt) +
        Number(this.salesItems[i]["salesub_tax_amnt"]);
      this.totVatAmntWithoutBillDisc =
        Number(this.totVatAmntWithoutBillDisc) +
        Number(this.salesItems[i]["salesub_tax_amnt_WithoutBillDisc"]);
    }
    if(this.sale_pay_type==4){
      this.changePay(4);
    }

    
     
    if(this.advanceAmount >  0){
      if( this.walletBalance == this.advanceAmount){
        this.AvilableAdAmount= this.advanceAmount;
      }
      if(this.walletBalance > this.advanceAmount){
        this.AvilableAdAmount= this.advanceAmount;
      }

      if(this.walletBalance < this.advanceAmount){
        this.AvilableAdAmount= this.walletBalance;
      }

      this.BalanceAmount =this.totItemprice - this.totItemDisc + this.totVatAmnt + this.ServiceAdditionaltotalAmount - this.AvilableAdAmount;

     
    }

    console.log("Available Advance Amunt");
    console.log(this.AvilableAdAmount);

    console.log("jjjjjjjjjjjeeeee");
    console.log((this.totItemprice - this.totItemDisc + this.totVatAmnt + this.ServiceAdditionaltotalAmount - this.AvilableAdAmount));
   
    //this.totalcreditCash =this.totItemprice - this.totItemDisc + this.totVatAmnt + this.ServiceAdditionaltotalAmount - this.AvilableAdAmount;
  
  }

  discountShareToItems() {
    this.is_bill_disc = false;
    let gttl =
      this.totItempriceWithoutBillDisc -
      this.totItemDiscWithoutBillDisc +
      this.totVatAmntWithoutBillDisc;
    let InpbillDisc = this.billDiscount || 0;
    this.salesItems.forEach((element, i) => {
      let grnd_totl = this.salesItems[i].grnd_totl_WithoutBillDisc;
      let billDiscPrec = (grnd_totl * 100) / gttl;
      let billDiscountAmt = (InpbillDisc / 100) * billDiscPrec;
      if (billDiscountAmt > 0) {
        if (this.salesItems[i].excl_vat == 1) {
          this.salesItems[i].item_bill_disc =
            this.salesItems[i].rate * this.salesItems[i].salesub_qty -
            ((grnd_totl - billDiscountAmt) * 100) /
              (this.salesItems[i].salesub_tax_per + 100);
        } else {
          this.salesItems[i].item_bill_disc = billDiscountAmt;
        }
      } else {
        this.salesItems[i].item_bill_disc = 0;
      }
      this.calcRates(i, true);
    });
  }
  discountToAll() {
    this.is_bill_disc = true;
    if (this.billDiscount) {
      this.totItemDisc = this.billDiscount;
    } else {
      this.totItemDisc = 0;
    }
    // multi case
    this.changePay(4);
  }
  clearAllDiscounts() {
    this.billDiscount = 0;
    this.totItemDisc = 0;
    this.clearDiscountShareToItems();
  }
  clearDiscountShareToItems() {
    let gttl =
      this.totItempriceWithoutBillDisc -
      this.totItemDiscWithoutBillDisc +
      this.totVatAmntWithoutBillDisc;
    let InpbillDisc = 0;
    this.salesItems.forEach((element, i) => {
      let grnd_totl = this.salesItems[i].grnd_totl_WithoutBillDisc;
      let billDiscPrec = (grnd_totl * 100) / gttl;
      let billDiscountAmt = (InpbillDisc / 100) * billDiscPrec;
      if (billDiscountAmt > 0) {
        if (this.salesItems[i].excl_vat == 1) {
          this.salesItems[i].item_bill_disc =
            this.salesItems[i].rate * this.salesItems[i].salesub_qty -
            ((grnd_totl - billDiscountAmt) * 100) /
              (this.salesItems[i].salesub_tax_per + 100);
        } else {
          this.salesItems[i].item_bill_disc = billDiscountAmt;
        }
      } else {
        this.salesItems[i].item_bill_disc = 0;
      }
      this.calcRates(i, true);
    });
  }

  validateSelectedGodown() {
    this.valErrors = {};
    // console.log(this.gd_id);
    if (this.gd_id === "" || this.gd_id === undefined) {
      this.valErrors.gd_id = { msg: "Required" };
      // console.log(this.valErrors);
      return false;
    } else {
      return true;
    }
  }

  validateBarcode() {
    this.valErrors = {};
    // console.log(this.barcode)
    if (!this.barcode || this.barcode === "" || this.barcode === undefined) {
      // console.log('barcode error');
      this.valErrors.barcode = { msg: "Enter Barcode" };
      return false;
    } else {
      return true;
    }
  }

  godonwChange() {
    delete this.valErrors.gd_id;
    this.resetProdForm();
  }

  resetProdForm() {}

  updateDate(cust) {
    if (cust) {
      let date = new Date(this.saleqt_date);

      if (cust.due_days) {
        date.setDate(date.getDate() + Number(cust.due_days));
      } else {
        date.setDate(date.getDate() + 30);
      }
      this.valid_till_date = new Date(date);
    }
  }

  selectTaxType(ptypeid) {
    // purchase_types = [
    //   { id: 1, name: 'Standard Rated Domestic' },
    //   { id: 2, name: 'Zero Rated Domestic' },
    //   { id: 3, name: 'Exempt Purchase' }

    if (ptypeid === 3 || ptypeid === 2) {
      // to set tax category with 0% tax percentage
      // this.taxListCategories = this.taxProdCategories.filter((taxCat) => Number(taxCat.taxcat_tax_per) == 0);

      // if (this.taxListCategories.length > 0) {
      //   this.prd_tax_cat_id = this.purch_type_taxcat_id = this.taxListCategories[0].taxcat_id;
      //   this.taxpercentage = this.taxListCategories[0].taxcat_tax_per
      // }
      this.zeroType = true;
    } else {
      this.zeroType = false;
    }
    this.discountShareToItems();
  }
  vaidateForm() {
    this.valErrors = {};
    // console.log(this.gd_id);
    let retVal = true;
    if (this.gd_id === "" || this.gd_id === undefined) {
      this.valErrors.gd_id = { msg: "Required" };
      // console.log(this.valErrors);
      retVal = false;
    }
    if (this.sale_pay_type == 2 || this.sale_pay_type == 4) {
      if (
        this.sale_acc_ledger_id === "" ||
        this.sale_acc_ledger_id === undefined ||
        this.sale_acc_ledger_id === null ||
        this.sale_acc_ledger_id == "0"
      ) {
        this.valErrors.sale_acc_ledger_id = { msg: "Required" };
        retVal = false;
      }
    }
    if (this.sale_pay_type == 3 || this.sale_pay_type == 4) {
      if (
        this.sale_bank_ledger_id === "" ||
        this.sale_bank_ledger_id === undefined ||
        this.sale_bank_ledger_id === null ||
        this.sale_bank_ledger_id == "0"
      ) {
        this.valErrors.sale_bank_ledger_id = { msg: "Required" };
        retVal = false;
      }
    }
    if (this.inv_type == 1 && (this.vat_no == "" || this.vat_no == null)) {
      this.valErrors.vat_no = { msg: "Required" };
      retVal = false;
    }

    if(this.sales_order_id && this.advanceAmount && this.totalcreditCash && (this.totalcreditCash > this.BalanceAmount)){
      
      // alert("hoyyyyyyyy");
      this.valErrors.totalcreditCash = { msg: "Required" };
      retVal = false;
      this.coreService.showMessage("Canot be compleated ...Receiving amount is more than balance Amount");
    }
    let below_mrp_alert = false;
    console.log('convert+this.salesItems');
    console.log(this.salesItems);
    
    this.salesItems.forEach((element, i) => {
      if (element.salesub_prd == "") {
        this.valErrors["salesub_prd_" + i] = { msg: "Required" };
        retVal = false;
      } else {
        if (element.salesub_qty == "" || element.salesub_qty <= 0) {
          this.valErrors["salesub_qty_" + i] = { msg: "Required" };
          retVal = false;
        }
         console.log("element.sb_id");
         console.log(element.sb_id);
        if(this.checkBatch && element.batches.length >0 && element.sb_id ==undefined || this.checkBatch && element.batches.length >0 && element.sb_id == 0){
          this.valErrors['batch_' + i] = {msg : 'Required'}
          retVal = false;
          alert("Please Select Batch..");
    
        }
        // console.log('sss-' + element.stock_qty)
        



        if (
          (element.sb_id != undefined && this.checkBatch && element.unit_available_stock <= 0 && this.disable_sales_without_stock) ||
          (this.disable_sales_without_stock &&
            element.unit_available_stock < element.salesub_qty)
        ) {
          alert("Sales without stock is disabled");
          this.valErrors["salesub_qty_" + i] = { msg: "Sales without stock" };
          retVal = false;
        }

      

        else if(
          (element.stock_qty <= 0 && this.disable_sales_without_stock) ||
          (this.disable_sales_without_stock &&
            element.stock_qty < element.salesub_qty)
        ) {
          alert("Sales without stock is disabled");
          this.valErrors["salesub_qty_" + i] = { msg: "Sales without stock" };
          retVal = false;
        }

       
       
        if (element.rate == "") {
          this.valErrors["rate_" + i] = { msg: "Required" };
          retVal = false;
        }
        if (
          !this.enable_sale_below_mrp &&
          element.rate < element.rate_org &&
          !this.cust_enable_sale_below_mrp
        ) {
          if (!below_mrp_alert) {
            alert("Sales below Sales Rate is disabled");
          }
          this.valErrors["rate_" + i] = { msg: "Sales below Sales Rate" };
          retVal = false;
          below_mrp_alert = true;
        }

        console.log('this.bs_prate',this.bs_prate);
        console.log('this.rate_org',element.rate );
        console.log('this.salesItems[i].base_qty',this.salesItems[i].base_qty );

        
        if (this.enable_sale_below_purch && (this.bs_prate*this.salesItems[i].base_qty
          > element.rate )) {
          this.valErrors["rate_" + i] = { msg: "Sales below Purchase Rate" };
          retVal = false;
        }

      }
    });
    // console.log(this.salesItems);
    // console.log(this.valErrors);

    return retVal;
  }
  addSales(print = false) {
    this.validationMsg = "";
    if (this.vaidateForm()) {
      let sqdisc = 0;
      if (!this.is_bill_disc) {
        sqdisc = 0;
      } else {
        sqdisc = this.billDiscount;
      }
      this.saleqt_date=(this.saleqt_date) ?this.apiService.formatDate(this.saleqt_date) : this.saleqt_date;
      this.valid_till_date=(this.valid_till_date) ?this.apiService.formatDate(this.valid_till_date) : this.valid_till_date;
      const searchval = {
        availabe_advance_amount:this.AvilableAdAmount,
        total_pay_amount:Number(this.totalcreditCash),
        sales_order_id :this.sales_order_id,
        saleqt_date: this.saleqt_date,
        valid_till_date: this.valid_till_date,
        service_additional_charges: this.service_additional_charges,
        sq_total: this.totItemprice - this.totItemDisc + this.totVatAmnt+this.ServiceAdditionaltotalAmount,
        sq_total_item_disc: this.totItemDisc,
        sq_disc: sqdisc,
        sq_tax: this.totVatAmnt,
        sale_items: this.salesItems,
        terms: this.terms,
        sales_note: this.sales_note,
        reference: this.order_no,
        cnvert_ref_no: this.cnvert_ref_no,
        sale_agent: this.sale_agent ? this.sale_agent : 0,
        sales_tax_type: this.purchtypesel - 1,
        sale_pay_type: this.sale_pay_type,
        cust_type: this.cust_id && this.cust_id.cust_id ? "2" : "1",
        cust_id: this.cust_id,
        sale_acc_ledger_id:
          this.sale_pay_type == 2
            ? this.sale_acc_ledger_id
            : this.sale_bank_ledger_id,
        inv_type: this.inv_type,
        inv_sub_type: this.inv_sub_type,
        cust_name: this.cust_id ? this.cust_id.name : "Cash Customer",
        vat_no: this.vat_no,
         // cntct_num: this.cust_id ? this.cust_id.mobile : "",
        cntct_num:this.cntct_num,
        cust_addr : this.cust_id ? this.cust_id.cust_home_addr : "", 
        sale_acc_ledger_id_cash : this.sale_pay_type ==4 ? this.sale_acc_ledger_id : "", 
        sale_acc_ledger_id_bank : this.sale_pay_type ==4 ? this.sale_bank_ledger_id : "", 
        card_amnt : this.sale_pay_type ==4 ? this.bank_amnt : 0, 
        cash_amnt : this.sale_pay_type ==4 ? this.cash_amnt : 0, 
      };
      
      // if(this.cmpny != 'FJK' && this.cmpny != 'FJKIN'){
      //   this.coreService.showMessage('Quick Sales Temporarily Blocked');
      //   return false;
      // }

      this.pageLoadingImg = true;
      // console.log('this.inv_type :' + this.inv_type);
      if(this.eInvoiceSupport && this.inv_type == 2 && this.print_style_tmp == 1000){
        if(this.einv_simplfied_print_style == 1){
          this.print_style = 1001;
        } else {
          this.print_style = 1002;
        }
      } else{
        this.print_style = this.print_style_tmp;
      }
      // console.log('print_style :' + this.print_style);

      this.apiService.addSales(searchval).subscribe((res) => {
        if (res.error != null) {
          this.resultobj = res.error;
          this.pageLoadingImg = false;
          for (var value in this.resultobj) {
            this.validationMsg += this.resultobj[value].toString() + "<br>";
          }
        }
        if (res.message) {
          if (print) {
            this.saleQtnData = res.message["preview"];
            // this.saleQtnData.sq_inv_type
            this.is_prew_description = this.saleQtnData["sales_sub"].filter(
              (x) => x.item_descrp
            );
            this.previewLoader = false;
            this.qr_inv = this.saleQtnData["qr_link"];
            $("#printviewBtn").click();
          } else {
            this.coreService.showMessage(res.message["msg"]);
          }
          this.pageLoadingImg = false;
          this.cust_id = null;
          this.sale_agent=null;
          this.cust_credit_limit=null;
          this.cust_balance=null;
          this.sale_bank_ledger_id=null;
          this.valid_till_date = null;
          this.vat_no = "";
          this.cntct_num='';
          this.order_no = "";
          this.cnvert_ref_no="";
          this.copySale="";
          this.service_additional_charges=[];
          this.ServiceAdditionaltotalAmount=0;
          this.additional_charges=[];
          this.additional_total_amount=0;
          this.doneMessage=false;
          this.advanceAmount = 0;
          this.walletBalance = 0;
          this.AvilableAdAmount =0;
          this.totalcreditCash= 0;
          this.BalanceAmount= 0;
          this.apiService.updateSalesOrderNotification();
          
          this.salesItems = [
            {
              salesub_prd: "",
              salesub_qty: null,
              base_qty: null,
              salesub_rate: null,
              salesub_unit_id: "",
              salesub_tax_per: null,
              prd_tax_cat_id: null,
              salesub_tax: "",
              item_desc: "",
              salesub_gd_id: "",
              prod_units: [],
              fuel_readings: {
                ob_reading:0,
                cb_reading:0,
              },
              unit: "",
              // qty: null,
              loadingImg: false,
              rate: null,
              disc_amnt: 0,
              item_disc_type: null,
              excl_vat: this.sale_vat_type ? 2 : 1,
              taxpercentage: null,
              itemRate: null,
              grnd_totl: null,
              base_unit_rate: null,
              item_disc: 0,
              item_bill_disc: 0,
              barcode: "",
              stock_qty: 0,
              sel_rate_type: 1,
              
            },
          ];
          this.readings=[];
          this.listQtn(1);
           this.getAdditonalCharge();
          this.nextRefNo();
          this.sumSaleTotal();
          setTimeout(() => {
            this.selPriduct.last.focus();
          }, 500);
          // chang paytype to cash after a sale
          if(this.sales_settings.ss_default_pay_type==2){
            this.sale_pay_type=3;
           }else if(this.sales_settings.ss_default_pay_type==3){
            this.sale_pay_type=4;
           }else{
            this.sale_pay_type=2;
           }
          this.searchCustomer("");
        }
      });
      this.apiService.updatebatchExNotification();
      this.apiService.updateEinvoiceNotificationChange();
    }
  }

  // condition for download pdf
  previewSales(id, data = null) {
    // data.sales_inv_type
    // console.log('invType :' + data.sales_inv_type);
    // console.log('data.is_e_inv :' + data.is_e_inv);
    // console.log('this.print_style_tmp :' + this.print_style_tmp);
    if(data && data.is_e_inv && data.sales_inv_type == 2 && this.print_style_tmp == 1000){
      if(this.einv_simplfied_print_style == 1){
        this.print_style = 1001;
      } else {
        this.print_style = 1002;
      }
    } else{
      this.print_style = this.print_style_tmp;
    }
    // console.log('print_style :' + this.print_style);
    let searchval = new FormData();
    searchval.append("sale_inv_no", id);
    this.previewLoader = true;
    this.apiService.getSalesInvPreview(searchval).subscribe((res: any) => {
      if (res.data) {
        this.saleQtnData = res.data["preview"];
        this.is_prew_description = this.saleQtnData["sales_sub"].filter(
          (x) => x.item_descrp
        );
        this.previewLoader = false;

        this.qr_inv = this.saleQtnData["qr_link"];

        if(this.list_sales[this.downloadingIndex].pdfLoadingImg){

          if (this.saleQtnData) {
            this.data = this.saleQtnData.sales_sub;
            this.ttlPage = Math.ceil(
              this.saleQtnData.sales_sub.length / this.noRowPerPage
            );
            this.lastpageIteemCount =
              this.saleQtnData.sales_sub.length % this.noRowPerPage;
            if (this.lastpageIteemCount == 0 || this.lastpageIteemCount > 7) {
              this.ttlPage = this.ttlPage + 1;
            }
            this.noPage = this.createRange(this.ttlPage);

            this.generateSalesOrderPdf1();

  
          }
        }


      }
    });
    this.apiService.updatebatchExNotification();
  }

  showList(val) {
    if (val) {
      // this.getAdditonalCharge();
      this.listing = false; // Invoice Page
      // setTimeout(() => {
      //   this.newInvoice(this.listing);
      // }, 500);
    } else {

      this.listing = true; // Listing Page
    }
  }

  additionalServiceCharges(data){
        this.service_additional_charges = data;
        this.ServiceAdditionaltotalAmount = data.ServiceAdditionaltotalAmount;
        // multi case
        this.changePay(4);
        
      }

  listQtn(pageNo = 1, keyword = "") {
    let searchval = new FormData();
    if(this.invoice_type &&this.eInvoiceSupport){
      searchval.append("invoiceType_id",this.invoice_type);
    }
    if (keyword != "") searchval.append("keyword", keyword);
    if (this.usr_hide_others_sales_data) {
      let hide_other = "hide";
      searchval.append("hide_others", hide_other);
    }
    this.searchLoadingImg = true;
    this.apiService.getSalesInvList(searchval, pageNo).subscribe((res) => {
      this.list_sales = res.data.data;
      this.curpage = res.data["current_page"];
      this.lastpage = res.data["last_page"];
      this.from = res.data["from"];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.searchLoadingImg = false;
    });
    this.apiService.updatebatchExNotification();
  }

  pgEnd(curr, end) {
    if (curr == end) this.pgend = true;
    else this.pgend = false;
  }

  pgStart(curr) {
    if (curr == 1) this.pgstart = true;
    else this.pgstart = false;
  }

  clearForm() {
    this.advanceAmount = 0;
    this.walletBalance = 0;
    this.AvilableAdAmount =0;
    this.totalcreditCash= 0;
    this.BalanceAmount= 0;
    this.service_additional_charges=[];
    this.ServiceAdditionaltotalAmount=0;
    this.additional_charges=[];
    this.additional_total_amount=0;
    this.doneMessage=false;
    this.cust_id = null;
    this.valid_till_date = null;
    this.vat_no = "";
    this.cntct_num='',
    this.order_no = "";
    this.cnvert_ref_no="";
    this.no_refno="";
    this.copySale="";
    this.billDiscount="";
    this.salesItems = [
      {
        salesub_prd: "",
        salesub_qty: null,
        base_qty: null,
        salesub_rate: null,
        salesub_unit_id: "",
        salesub_tax_per: null,
        prd_tax_cat_id: null,
        salesub_tax: "",
        item_desc: "",
        salesub_gd_id: "",
        prod_units: [],
        fuel_readings: {
          ob_reading:0,
          cb_reading:0,
        },
        unit: "",
        // qty: null,
        loadingImg: false,
        rate: null,
        disc_amnt: 0,
        item_disc_type: null,
        excl_vat: this.sale_vat_type ? 2 : 1,
        taxpercentage: null,
        itemRate: null,
        grnd_totl: null,
        base_unit_rate: null,
        item_disc: 0,
        item_bill_disc: 0,
        barcode: "",
        stock_qty: 0,
        sel_rate_type: 1,
      },
    ];
    this.getAdditonalCharge();
    this.nextRefNo();
    this.sumSaleTotal();
    setTimeout(() => {
      this.selPriduct.last.focus();
    }, 500);
  }

  searchprdBybarcode(search: any) {
    const searchval = new FormData();
    searchval.append("prd_barcd", search);
    searchval.append("godown_id", this.gd_id);
    searchval.append("hide_prd_by_cat_sale", '1');
    this.apiService.searchBarcode(searchval).subscribe((res) => {
      this.prdcts = res["data"];
      this.srch_length = this.prdcts.length;
      // console.log(this.prdcts);
      // console.log(this.srch_length);
    });
  }

  searchAgents(search: string) {
    const searchval = new FormData();
    searchval.append("ledger_name", search);
    this.apiService.searchAgents(searchval).subscribe((res) => {
      this.sales_agents = res["data"];
    });
  }

  getGodownWiseStock(prd_id) {
    const searchval = new FormData();
    searchval.append("prd_id", prd_id);
    // this.godownStocksTotal = 0;

    this.apiService.getGodownWiseStock(searchval).subscribe((res) => {
      this.godownStocks = res["data"]["data"];
      this.p_rate = res["data"]["l_p_rate"];
      this.avg_p_rate = res["data"]["avg_p_rate"];
      // this.godownStocksTotal = res['data']['total'];
    });
  }

  getLastSaleToCust(prd_id, cust_id) {
    const searchval = new FormData();
    searchval.append("prd_id", prd_id);
    searchval.append("cust_id", cust_id);
    if (this.usr_hide_others_sales_data) {
      searchval.append("user_id", "1");
    }
    this.apiService.getLastSaleToCust(searchval).subscribe((res) => {
      this.lastInvoice = res["data"];
      // this.lastCust = 0;

      // if (this.lastInvoice[0])
      //   this.lastCust = this.lastInvoice[0]['salesub_rate'];
      // else
      // this.lastCust = 0;
    });
  }
  buttnclck() {
    $("#popup").slideUp();
    this.showbutton = true;
  }
  buttnclck1() {
    $("#popup").slideDown();
    this.showbutton = false;
  }

  rate_history(prd_id) {
    this.hist_prd_id = prd_id;
    this.desktoken = localStorage.cmptoken;

    // if(this.usr_hide_others_sales_data){
    //   this.user_id=this.user_id;

    //   }else{
    //     this.user_id='';
    //   }
    //console.log(localStorage.setItem("cmptoken", res.cmptoken););

    $("#ifrm").attr(
      "src",
      environment.php_api_server +
        "/product-history?prod_id=" +
        prd_id +
        "&desktoken=" +
        this.desktoken +
        "&user=" +
        this.user_id
        +"&Lang="+this.translate.currentLang
    );

    // const searchval = new FormData();
    // searchval.append("product_id", prd_id);
    // this.apiService.rate_history(searchval).subscribe((res) => {
    //   this.rate_histry = res['data']['sales'];
    //   this.product_name = res['data']['product'];
    //   // console.log(this.product_name);

    // });
  }
 

  generateSalesOrderPdf(id,i) {
    this.downloadingIndex = i;
    this.list_sales[i].pdfLoadingImg = true;
    this.previewSales(id);
    

  }

  generateSalesOrderPdf1() {
    const options = {
      // margin: 0,
      // filename: this.saleQtnData.sq_branch_qt_no + ".pdf",
      // image: { type: 'jpg', quality: 0.98 },
      // html2canvas: { scale: 2, y: 0,  scrollY: 0, useCORS: true},
      // jsPDF: { format: 'A4' },
      // pagebreak:{after:".page-break"}


      filename: this.saleQtnData.sq_branch_qt_no + ".pdf",
      image: { type: "png", quality: 0.98 },
      html2canvas: { scale: 2,y: 0,  scrollY: 0, allowTaint: false, useCORS: true },
      jsPDF: { orientation: "p",  unit: "mm", format: "a4", precision: 5 },
      pagebreak: { after: ".page-break" },
    };

    setTimeout(() => {
      const content = document.getElementById("sale-order-print");
      html2pdf().from(content).set(options).then(() =>{
        this.list_sales[this.downloadingIndex].pdfLoadingImg = false;
        this.downloadingIndex = -1;
      }).save()
    }, 1000);
  }

  createRange(number, start = 0) {
    let noPage = [];
    for (var i = start; i < number; i++) {
      noPage.push(i);
    }
    return noPage;
  }

  copyQuickSales(refno) {
    this.getAdditonalCharge();
    const searchval = new FormData();
    searchval.append("sq_inv_no", refno);
    // this.copyLoading=true; 


    this.pageLoading = true
    this.apiService.copySalesRef(searchval).subscribe((res) => {
     if(res.data){
      this.pageLoading = false
      this.listing = false;
      this.customer = res.data.customer;
      this.cust_id = res.data.customer;
      this.vat_no=res.data.sales_cust_tin

      // if(this.customer){
      //   this.vat_no=res.data.customer.vat_no

      // }else {
      //   this.vat_no = ""; // Clear the error message if reference is valid
      // }

      
      this.sales_agents = res.data.sales_agent_ledger_id
      this.sale_agent = res.data.sales_agent_ledger_id
      console.log(res.data.sales_agent_ledger_id);
      
      // this.godown_all = res.data.godown_id
      this.gd_id = res.data.godown_id
      // this.purchase_types = res.data.sales_tax_type
      // this.purchtypesel = res.data.sales_tax_type
      this.sale_pay_type=res.data.sales_pay_type==1?res.data.sales_pay_type:2
      // sale_pay_type:element['sale_pay_type']==1?element['sale_pay_type']:2,
  

      var ids = res.data.sales_sub.map(v => (v.salesub_prd_id));


      this.stkprd_all = [];
      this.no_refno=''
      this.searchStkPrdForcopy(ids);
      // console.log('hii');
      var myInterval = setInterval(() => {
        // console.log(ids);
        if (this.stkprd_all.length > 0) {
          // this.qt_no =res.data.sales_odr_branch_inv;
          clearInterval(myInterval);

          this.copyItemLoading(res);
        }

      }, 500);
     
     }else{
      console.log('hello');
      
      this.customer=''
      this.cust_id=''
      this.sale_agent=''
      this.vat_no=''
      this.cntct_num=''
      this.pageLoading = false
      this.no_refno='no matching data found'
      this.salesItems = [];
      this.addNewRow('')


     }
    })

  }

  copyItemLoading(res) {
    this.billDiscount=0;
    this.salesItems = [];

    var tmp = {};
    this.salesItems.splice(0, 1);
    res.data.sales_sub.forEach((element, i) => {

  
      let selProd = this.stkprd_all.find(element1 => element1.bs_prd_id == element['salesub_prd_id']);
      let salesub_unit_id = element['salesub_unit_id']
      var tmp = {

        salesub_prd: selProd,
        salesub_prd_id:selProd.bs_prd_id,
        salesub_tax_per:selProd.prd_tax,
        
        
        // prd_name:element['prd_name'], 
        salesub_qty: element['salesub_qty'],
        sel_rate_type:1,
        // salesub_tax_amnt: element['salesub_tax_amnt'],
        base_qty: element['base_qty'],
        rate: element['salesub_rate'],
        item_total: element['salesub_amnt'],

        unit_name: element['prd_unit_name'],
        salesub_tax_amnt: element['salesub_tax_amnt'],
        prd_tax_cat_id: element['prd_tax_cat_id'],
        salesub_tax: element['salesub_tax'],
        //item_desc: element['item_disc'],
        item_desc: 0,
        Item_descp: element['Item_descp'],
        salesub_gd_id: element['salesub_gd_id'],
        prod_units: [],
        fuel_readings: {
          ob_reading:0,
          cb_reading:0,
        },
        sb_id: element['sb_id'],
        unit: '',
        // qty: null,
        loadingImg: false,
         
        disc_amnt: 0,
        //disc_amnt: element['item_disc'],
        //item_disc_type: element['item_disc_type'],
        excl_vat: element['vat_type'],

        taxpercentage: null,
        itemRate: null,
        grnd_totl: element['salesub_amnt'] + element['salesub_tax_amnt'],
        base_unit_rate: null,
        //item_disc: element['item_disc'],
        item_disc: 0,
        item_bill_disc: 0,
        barcode: element['prd_barcode'],
        stock_qty: 0,
        batch_ex_date: null,
        grnd_totl_WithoutBillDisc: element['sales_ord_sub_amnt'] + element['salesub_tax_amnt'],
        salesub_rate_without_bill_disc: element['salesub_rate'],
        salesub_rate: element['salesub_rate'],
        item_disc_WithoutBillDisc: element['item_disc'],
        salesub_tax_amnt_WithoutBillDisc: element['salesub_tax_amnt'],
        

      }
   

      this.salesItems.push(tmp);
      // const prdUnts = this.selctedProd.prod_units.filter((ProdUnits) => ProdUnits.produnit_unit_id === this.unit);
      // var unit_rate = (this.sel_rate_type == 1) ? prdUnts[0].sur_unit_rate : prdUnts[0].sur_unit_rate2;
      // this.rate_org = (unit_rate ? unit_rate : prdUnts[0].unit_base_qty * this.selctedProd.base_unit_rate);

      //  this.selectedProduct(i);
      var myInterval1 = setInterval(() => {
        if (this.salesItems[i].prod_units.length > 0) {
          // this.qt_no =res.data.sales_odr_branch_inv;
          // this.nextRefNo1();
          clearInterval(myInterval1);

          // this.salesItems[i].sb_id = element['sb_id'];
          // this.salesItems[i].salesub_unit_id = element['salesub_unit_id'];
          // this.salesItems[i].salesub_qty = element['salesub_qty'];
          // this.salesItems[i].salesub_rate = element['salesub_rate'];
          // this.salesItems[i].Item_descp = element['item_desc'];
          // this.salesItems[i].rate = element['salesub_rate'];

          // if(element['sb_id'] > 0)
          // {
          //   this.updateStock(i);
          //   // this.batchStock(i);
          // }
          // this.calcRates(i);

        }
      }, 500);


      // this.searchStkPrd(element['prd_name'], this.salesItems.length -1);
      this.getStockDetails1(this.salesItems.length - 1, salesub_unit_id);
    });
    this.sumSaleTotal();
    this.listing = false;
   

    // this.editMessage = false;
    // setTimeout(() => {
    //   this.selPriduct.last.focus();
    // }, 500);
  }

  getStockDetails1(i, unit) {
    this.getSalesSetting();

     console.log("i");
     console.log(i);

     console.log("this.salesItems[i].stock_qty");
     console.log(this.salesItems[i].stock_qty);


    
    this.salesItems[i].loadingImg = true;
    let prd_id = this.salesItems[i].salesub_prd.prd_id;
    const searchval = new FormData();
    searchval.append("prd_id", prd_id);
    searchval.append("gd_id", this.gd_id);
    searchval.append("price_group_id", this.price_group_id);
    this.apiService.getsaleProdDetails(searchval).subscribe((res) => {
      let selProdInfo = res["data"];
      this.salesItems[i].prod_units = selProdInfo.prod_units;
      this.salesItems[i].salesub_unit_id = unit;
      this.salesItems[i].loadingImg = false;
      this.salesItems[i].batches = selProdInfo.batches;

    });


    if (this.salesItems[i].sb_id) {
      // this.selctedProd.stock_qty = 333
      // getGodownBatchStock(){}
      const searchval = new FormData();
      searchval.append("prd_id", this.salesItems[i].salesub_prd.prd_id);
      searchval.append("gd_id", this.gd_id);
      searchval.append("price_group_id", this.price_group_id);
      searchval.append("batch_id", this.salesItems[i].sb_id);
      this.batchLoading = true;
      this.apiService.getGodownBatchStock(searchval).subscribe((resp) => {
        this.batchLoading = false;

        if(resp['data']['gdStock']){

          this.salesItems[i].unit_available_stock =
          resp["data"]["gdStock"].gbs_qty || 0;
          this.salesItems[i].stock_qty =
          resp["data"]["gdStock"].gbs_qty || 0;  

        }

        else{
          this.salesItems[i].sb_id = 0;
          this.salesItems[i].unit_available_stock = 0;
         
          this.salesItems[i].stock_qty = 0;
          

        }
      
        //this.selectedUnit(i);
      });
    }
    
    else{
      this.batchLoading = true;
        // this.salesItems[i].stock_qty = this.stock_qty[i];
        this.salesItems[i].unit_available_stock = this.salesItems[i].stock_qty;
        this.batchLoading = false;
     
    }
  }

  searchStkPrdForcopy(ids) {
    let searchval = new FormData();
    searchval.append("ids", ids);
    searchval.append('stk_stat', '1');
    this.apiService.getStockbyItemByIds(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];
      this.srch_count = this.stkprd_all.length;
    });
  }

  eInvoiceResubmit(sales_inv_no,i){
    if(confirm('Do you wish to continue?')){
      let tmp = {
        type: 'sales',
        ref_no : sales_inv_no
      }
      this.list_sales[i].resubmitLoadingImg = true;
      this.apiService.eInvoiceResubmit(tmp).subscribe((res) => {
        this.list_sales[i].resubmitLoadingImg = false;
        this.coreService.showMessage(res.msg, 4000);
        if (res.status) {
          this.listQtn(1);
          this.apiService.updateEinvoiceNotificationChange();
        }
      });
    }
  }

  

  getAdditonalCharge() {
    this.apiService.getAllServiceTypesDatas().subscribe((res) => {
      this.servicetypes = res.data;
     
    });

  }


  searchStkPrdSalesOrder(ids) {

    console.log("3333333333333333");
    console.log(ids);
    let searchval = new FormData();
    searchval.append("ids", ids);
    searchval.append('stk_stat', '1');
    this.apiService.getStockbyItemByIds(searchval).subscribe((res) => {

     
      this.sales_order_stkprd_all = res['data'];
      this.srch_count = this.sales_order_stkprd_all.length;

      console.log("res1111111");
      console.log(res);
    });
  }


  searchStkPrdForEdit(res) {
    this.salesItems = [];

    

    var tmp = {};
    this.salesItems.splice(0, 1);
    res.data.sales_order_sub.forEach((element, i) => {

  
      let selProd = this.sales_order_stkprd_all.find(element1 => element1.bs_prd_id == element['salesub_prd']);
     
      
      let salesub_unit_id = element['salesub_unit_id']
      var tmp = {

        salesub_prd: selProd,
        salesub_prd_id:selProd.bs_prd_id,
        salesub_tax_per:selProd.prd_tax,
        
        
        // prd_name:element['prd_name'], 
        salesub_qty: element['salesub_qty'],
        sel_rate_type:1,
        // salesub_tax_amnt: element['salesub_tax_amnt'],
        base_qty: element['base_qty'],
        rate: element['rate'],
        item_total: element['sales_ord_sub_amnt'],

        unit_name: element['prd_unit_name'],
        salesub_tax_amnt: element['salesub_tax_amnt'],
        prd_tax_cat_id: element['prd_tax_cat_id'],
        salesub_tax: element['sales_ord_sub_tax'],
        item_desc: element['item_desc'],
        Item_descp: element['item_desc'],
        salesub_gd_id: element['sales_ord_sub_gd_id'],
        prod_units: [],
        fuel_readings: {
          ob_reading:0,
          cb_reading:0,
        },
        sb_id: element['sb_id'],
        unit: '',
        // qty: null,
        loadingImg: false,

        disc_amnt: element['item_disc'],
        item_disc_type: element['item_disc_type'],
        excl_vat: element['vat_type'],

        taxpercentage: null,
        itemRate: null,
        grnd_totl: element['sales_ord_sub_amnt'] + element['salesub_tax_amnt'],
        base_unit_rate: null,
        item_disc: element['item_disc'],
        item_bill_disc: 0,
        barcode: element['prd_barcode'],
        stock_qty: selProd.bs_stock_quantity_shop,
        batch_ex_date: null,
        grnd_totl_WithoutBillDisc: element['sales_ord_sub_amnt'] + element['salesub_tax_amnt'],
        salesub_rate_without_bill_disc: element['rate'],
        salesub_rate: element['rate'],
        item_disc_WithoutBillDisc: element['item_disc'],
        salesub_tax_amnt_WithoutBillDisc: element['salesub_tax_amnt'],
        

      }
     

      this.salesItems.push(tmp);
      // const prdUnts = this.selctedProd.prod_units.filter((ProdUnits) => ProdUnits.produnit_unit_id === this.unit);
      // var unit_rate = (this.sel_rate_type == 1) ? prdUnts[0].sur_unit_rate : prdUnts[0].sur_unit_rate2;
      // this.rate_org = (unit_rate ? unit_rate : prdUnts[0].unit_base_qty * this.selctedProd.base_unit_rate);

      //  this.selectedProduct(i);
      // var myInterval1 = setInterval(() => {
      //   if (this.salesItems[i].prod_units.length > 0) {
      //     // this.qt_no =res.data.sales_odr_branch_inv;
         
      //     clearInterval(myInterval1);

        

      //   }
      // }, 500);


      // this.searchStkPrd(element['prd_name'], this.salesItems.length -1);
      this.getStockDetails1(this.salesItems.length - 1, salesub_unit_id);
    });
    this.sumSaleTotal();
    this.listing = false;
   

    // this.editMessage = false;
    // setTimeout(() => {
    //   this.selPriduct.last.focus();
    // }, 500);
  }

  getGeneralSetting() {

    this.apiService.getGeneralSetting().subscribe((res) => {

      if(res['data']){
        this.g_settings = res['data'];
        this.generalSearch_type = this.g_settings.g_s_search_type;
      }else{
        this.generalSearch_type=0;
      }
      
    });

  }


  searchcustvat(Vatnomber){
    const searchval = new FormData();
      searchval.append('cust_vat_no', Vatnomber)
      if(this.eInvoiceSupport){
        searchval.append('einvoice_details', '1');
      }
      this.apiService.getCustomerByCode(searchval).subscribe((res) => {
        if (res['data'] && res['data'].length >0) { 
          this.customer  = res['data'];
          this.myselect.open();
          this.srchmsg=null;
          this.noCustomer = false;
        }
        else {
          this.srchmsg= res['msg']
          this.noCustomer = true;
          this.cntct_num ='';
          this.cust_id=null;
          this.clearCustomer();
        }
  
      });

  }

  searchCusPhone(phone){

    const searchval = new FormData();
      searchval.append('cust_phone_number', phone)
      if(this.eInvoiceSupport){
        searchval.append('einvoice_details', '1');
      }
      this.apiService.getCustomerByCode(searchval).subscribe((res) => {
        if (res['data']) {
          this.cust_id = res['data'];
          this.srchmsg=null;
          this.noCustomer = false;
            this.selectCustomer(this.cust_id);

        }
        else {
          this.srchmsg= res['msg'];
          this.noCustomer = true;
           this.clearCustomer();
          this.vat_no = '';
        }
  
      });


  }

  clearCustomer() {
    this.cust_id = '';
    this.cust_balance = 0;
    this.cust_bal_sign = "";
  }

  startPrint() {
    $('#printButton').click();
  }

}

