import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { ApiService } from '../../../../../service/api.service';
import { CoreService } from '../../../../../service/core.service';
import { TranslateService } from '@ngx-translate/core';
//irfan
import { Group } from '../../../../../model/group.model';
import { Ledger } from '../../../../../model/ledger.model';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  resultobj: any = {};
  info = [];
  log_info = [];
  filter_types = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];
  pay = {
    pay_no: '',
    doc_no:'',
    pay_date: new Date(),
    pay_acc: { ledger_id : 0 },
    note: '',
    ttlAmount: 0,
    grandTtlAmount: 0,
    ttlVatAmount: 0,
    pay_balance: 0,
    pay_bal_sign: '',
    godown_id: 0,
    ledgers: [{
      sub_id: '',
      ledger: <any>{},
      amount: <any>'',
      narration: '',
      vatIncExc: 0,
      vat_per: 0,
      vat_amount: 0,
      actlAmount: 0,
      fnlAmount: 0,
      pay_balance: 0,
      pay_bal_sign: '',
      accLoading: false,
      supp_default_currency_id: 0,
      exchange_rate:1,
      amount_sup_currency:<any>'',
      selcurrency:<any>{},
      wallet_amt: 0,
      chk_cust_pay_wallet_effect: false,
      is_both_cust_suppl : false,
      wallet_tmp: 0
    }]
  };
  // pay.pay_acc: any = {};
  account = [];
  nonAccount = [];
  isSubmitInProg = false;

  curpage = 1;
  lastpage = 1;
  //  perpage = 20;
  totalItem = 0;

  log_curpage = 1;
  log_lastpage = 1;
  log_perpage = 20;
  log_totalItem = 0;

  curForm = 'Create';
  pageLoadingImg = false;
  log_pageLoadingImg = false;

  langText = {
    create: '',
    update: ''
  };
  nextId = '';
  accLoading = false;
  up: any;
  payPriviewLoader:any;
  printPreview:any;
  log_printPreview: any;
  cmpny = '';
  userType = '';
//irfan
pageLoadingImg1 = false;
isSubmitInProg1 = false;
  customer = {
    is_supplier:false,
    id: '',
    ledger_id: '',
    name: '',
    alias: '',
    code: '',
    email : '',
    mobile: '',
    vat_no: '',
    op_bal: 0,
    op_bal_type: true,
    brch_id:0,
    usr_type:0,
    add: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    del: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    due_day: '',
    van_line: 0,
    price_group: 1,
    note: ''
  };
  ledger_name: any;
  ledger_accgrp_id: any;
  ledger_branch_id: any;
  ledger_alias: any;
  ledger_code: any;
  ledger_email: any;
  ledger_mobile: any;
  pageFrom: any;
  pgend: boolean;
  pgstart: boolean;
  ledger_due: any;
  ledger_tin: any;
  ledger_address: any;
  ledger_notes: any;
  ledgersel: any;
  selData: any = { ledger_id: null, error: null, ledger_name: null, message: null, };
  group_all: string[];
  ledger_all: string[];
  branch_all: string[];
  leadger: any;
  private modalRef: NgbModalRef;
  closeResult: string;  
  customer_type = false;
  printError: any;
  searchleadger: any;
  supplier = false;
  godowns: any;
  priceGroupList = [];
  ledger_pricegrp_id = 1;
  isCostCategoryLedger = false;
  costcenterList:any;
  isCCContentLoading = false;
  ccindex:any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  branch_address: any;
  branch_phone: any;
  branch_mob: any;
  savedTemplate = {
    title_text: '',
    color: '#000',
    email_color: '#000',
    font_size: '12',
    orientation: "P",
    paper: 'A4-P',
    m_left: '',
    m_top: '',
    m_right: '',
    m_bottom: '',
    logo: false,
    header_type: 1,
    header_img: [],
    header_all_page: false,
    header_due: false,
    sl_no: false,
    item_desc: false,
    tax_detail: false,
    tax_type: 0,
    name_type: 'N',
    footer_text: ''
  }
  logoImgUrl: string;
  tempImgUrl: string;
  multipleCurrency = false;
  currencies = [];
  country_dec: number=2;
  count_dec = 2;
  vans: any;
  allvans: any;
  usr_hide_others_sales_data: any;
  usr_block_pre_date_select: any;
  minDate: Date;
  constructor(private apiService: ApiService, private coreService: CoreService, private translate: TranslateService,private modalService: NgbModal) { }

  ngOnInit() {
    this.translate.get(['Common.create', 'Common.update']).subscribe((res: string) => {
      this.langText.create = res['Common.create'];
      this.langText.update = res['Common.update'];
      this.curForm = this.langText.create;

      this.apiService.getClientSettingBykey({ key: 'multiple_currency_support' }).subscribe((res) => {
        if (res['data']) {
          this.multipleCurrency = (res['data']['cs_value']) ? true : false;
        }
      });
    });
    this.usr_hide_others_sales_data = this.coreService.getUserData('usr_hide_othr_sales_data');
    this.getNextPaymentId();
    this.getAllAccount();

    this.searchAllVans('');
    this.getAllNonAccountLedgers();
    this.getInfo(this.curpage);
    this.getLogInfo(this.log_curpage);
    this.getAllCurrencies();
    this.up = this.coreService.getUserPrivilage();
    this.cmpny = this.coreService.getUserData('cmpny');
    this.userType = this.coreService.getUserData('user_type');
    //irfan
    this.getAllGroup();
    this.listGodowns();
     this.apiService.getAllPriceGroup().subscribe((res) => {
      this.priceGroupList = res.data;
    });
    this.getTemplete();
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name= this.coreService.getUserData('branch_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.branch_address= this.coreService.getUserData('branch_address');
    this.branch_phone= this.coreService.getUserData('branch_phone');
    this.branch_mob= this.coreService.getUserData('branch_mob');


    this.country_dec=this.coreService.setDecimalLength();
    this.count_dec=this.coreService.getUserData('branch_deci_length')?this.coreService.getUserData('branch_deci_length'):2;
    this.usr_block_pre_date_select = this.coreService.getUserData("usr_block_pre_date_select");
    if(this.usr_block_pre_date_select){
      this.minDate= new Date();
    }else{
      this.minDate= new Date(this.coreService.getUserData("branch_open_date"));
    }

  }
  getAllCurrencies(){
    this.apiService.listAllCurrency().subscribe((res) => {
      this.currencies = res.data;
    });
  }
  listGodowns() {
    let searchval = new FormData();
    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godowns = res['data'];

    });
  }
  
  getTemplete() {

    this.apiService.getSettings().subscribe((res) => {

      this.savedTemplate = {
        title_text: res.data.prnt_title_text,
        color: '#000',
        email_color: '#000',
        font_size: '12',
        orientation: "P",
        paper: 'A4-P',
        m_left: '',
        m_top: '',
        m_right: '',
        m_bottom: '',
        logo: res.data.prnt_header_logo,
        header_type: res.data.prnt_header_type,
        header_img: [],
        header_all_page: false,
        header_due: false,
        sl_no: false,
        item_desc: false,
        tax_detail: false,
        tax_type: 0,
        name_type: 'N',
        footer_text: res.data.prnt_display_footer_text
      }
      if (res.data.prnt_header_logo) {
        this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
      } else {
        this.logoImgUrl = '';
      }

      if (res.data.prnt_header_type == 2) {
        this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
      } else {
        this.tempImgUrl = '';
      }

    });
  }


  getNextPaymentId() {
    this.apiService.getNextPaymentId().subscribe((res) => {
      this.nextId = res.data.ref_no;
    });
  }
  getAccLedgerBalance() {
    this.accLoading = true;
    this.apiService.getLedgerBalance({ ledger_id: this.pay.pay_acc.ledger_id }).subscribe((res) => {
      this.accLoading = false;
      this.pay.pay_balance = res.data.bal_without_sign;
      this.pay.pay_bal_sign = res.data.bal_sign;
    });
  }

  getLedgerBalance(i, payno=0) {
    console.log(this.pay.ledgers[i]);
    this.pay.ledgers[i].wallet_amt = 0;
    this.pay.ledgers[i].wallet_tmp = 0;
    this.pay.ledgers[i].chk_cust_pay_wallet_effect = false;
    this.pay.ledgers[i].is_both_cust_suppl = false;
    if(this.pay.ledgers[i].ledger && this.pay.ledgers[i].ledger.is_cost_category){
      this.isCostCategoryLedger = true;
      this.isCCContentLoading = true;
      this.ccindex = i;
      this.apiService.getCostCenter({ledger_id:this.pay.ledgers[i].ledger.ledger_id, refno: payno, vch_type:0}).subscribe((res) => {
        this.costcenterList = res.data;
        this.isCCContentLoading = false;
      });
    } else{
      this.isCostCategoryLedger = false;
      this.costcenterList = [];
      this.isCCContentLoading = false;
      this.ccindex = null;
    }
    this.pay.ledgers[i].accLoading = true;
    // ledger_accgrp_id = 20 mean supplier some time ledger_accgrp_id customer (21) may be both supplier and customer
    if(this.pay.ledgers[i].ledger && (this.pay.ledgers[i].ledger.ledger_accgrp_id == 20 || this.pay.ledgers[i].ledger.ledger_accgrp_id == 21)){
      var isSupplier = false;
      this.apiService.getSupplierCurrencyDetails({ ledger_id: this.pay.ledgers[i].ledger.ledger_id }).subscribe((res) => {
        if(res.data){
          isSupplier = true;
          this.pay.ledgers[i].supp_default_currency_id = res.data.default_currency_id;
          this.pay.ledgers[i].selcurrency = this.currencies.find(x => x.cur_id == res.data.default_currency_id);
          if(payno == 0){
            this.pay.ledgers[i].exchange_rate = this.pay.ledgers[i].selcurrency.cur_exchange_rate;
          }
        } else{
          this.pay.ledgers[i].supp_default_currency_id = 0;
          this.pay.ledgers[i].exchange_rate = 1;
          this.pay.ledgers[i].selcurrency = {};
        }
      });

      // pay back customer wallet amount, (ledger_accgrp_id == 21) then find wallet balance if greater tha zero , then maximum payment limit wallet amount 
      // if wallet zero any amount can pay
      this.apiService.getCustomerBalance({ cust_ledger_id: this.pay.ledgers[i].ledger.ledger_id }).subscribe((res) => {
        if(!isSupplier){
          this.pay.ledgers[i].wallet_amt = res.data.wallet;
        } else{
          this.pay.ledgers[i].wallet_amt = 0;
          this.pay.ledgers[i].wallet_tmp = res.data.wallet;
          this.pay.ledgers[i].chk_cust_pay_wallet_effect = false;
          this.pay.ledgers[i].is_both_cust_suppl = true;
        }
      });
  

    } else{
      this.pay.ledgers[i].supp_default_currency_id = 0;
      this.pay.ledgers[i].exchange_rate = 1;
      this.pay.ledgers[i].selcurrency = {};
    }
    if(this.pay.ledgers[i].ledger.ledger_id){
      this.apiService.getLedgerBalance({ ledger_id: this.pay.ledgers[i].ledger.ledger_id }).subscribe((res) => {
        this.pay.ledgers[i].accLoading = false;
        this.pay.ledgers[i].pay_balance = res.data.bal_without_sign;
        this.pay.ledgers[i].pay_bal_sign = res.data.bal_sign;
        // supp_default_currency_id: 0,
        // selcurrency:<any>{} 
      });
    }
  }

  updateWalletAmt(i){
    if(this.pay.ledgers[i].chk_cust_pay_wallet_effect){
      this.pay.ledgers[i].wallet_amt = this.pay.ledgers[i].wallet_tmp;
    } else{
      this.pay.ledgers[i].wallet_amt = 0;
    }
  }

  getInfo(pageNo,formdata=null) {
   
    let searchval = formdata ? new FormData(formdata) : new FormData();
    if(this.usr_hide_others_sales_data){ 
      let hide_other='hide';
      searchval.append("hide_others", hide_other);
    }
    this.pageLoadingImg = true;
    this.apiService.getAllPayment(pageNo,searchval).subscribe((res) => {
      this.info = res.data.data;
      this.pageFrom = res.data.from;
      this.pageLoadingImg = false;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      
    });
  }
  pgEnd(curr, end) {
    if (curr == end) {
      this.pgend = true;
    }
    else {
      this.pgend = false;
    }

  }

  pgStart(curr) {
    if (curr == 1) {
      this.pgstart = true;
    }
    else {
      this.pgstart = false;
    }
  }

  getLogInfo(pageNo) {
    let searchval = new FormData();   
    if(this.usr_hide_others_sales_data){ 
      let hide_other='hide';
      searchval.append("hide_others", hide_other);
    }
    this.pageLoadingImg = true;
    this.apiService.getPaymentLog(searchval,pageNo, this.log_perpage).subscribe((res) => {
      this.log_info = res.data.data;
      this.log_pageLoadingImg = false;
      this.log_curpage = res.data.current_page;
      this.log_lastpage = res.data.last_page;
      this.log_totalItem = res.data.total;
    });
  }
  
  searchLogInp(e) {
    let searchval = new FormData();      
    searchval.append("search", e);  
    if(this.usr_hide_others_sales_data){ 
      let hide_other='hide';
      searchval.append("hide_others", hide_other);
    }
    this.pageLoadingImg = true;
    this.apiService.filterPaymenttLog(searchval,1, this.log_perpage).subscribe((res) => {
      this.log_info = res.data.data;
      this.log_pageLoadingImg = false;
      this.log_curpage = res.data.current_page;
      this.log_lastpage = res.data.last_page;
      this.log_totalItem = res.data.total;
    });
  }

  searchInp(e,evt) {
    if(evt.keyCode === 13 || !e){
      this.pageLoadingImg = true;

      const searchval = new FormData();
      searchval.append('search', e);
      if(this.usr_hide_others_sales_data){ 
        let hide_other='hide';
        searchval.append("hide_others", hide_other);
      }
      this.apiService.searchPaymentList(searchval).subscribe((res) => {
        this.info = res.data.data;
        this.pageFrom = res.data.from;
        this.pageLoadingImg = false;
        this.curpage = res.data.current_page;
        this.lastpage = res.data.last_page;
        this.totalItem = res.data.total;
        this.pgEnd(this.curpage, this.lastpage);
        this.pgStart(this.curpage);
       });
    }  
  }

  getAllAccount() {
    this.apiService.getAllAccount().subscribe((res) => {
      this.account = res.data;
    });
  }
  getAllNonAccountLedgers() {
    this.apiService.getAllNonAccountLedgers().subscribe((res) => {
      this.nonAccount = res.data;
    });
  }
  addMore() {
    this.pay.ledgers.push({
      sub_id: '',
      ledger: { ledger_id : 0 },
      amount: '',
      narration: '',
      vatIncExc: 0,
      vat_per: 0,
      vat_amount: 0,
      actlAmount: 0,
      fnlAmount: 0,
      pay_balance: 0,
      pay_bal_sign: '',
      accLoading: false,
      supp_default_currency_id: 0,
      amount_sup_currency:<any>'',
      exchange_rate:1,
      selcurrency:<any>{},
      wallet_amt: 0,
      chk_cust_pay_wallet_effect:false,
      is_both_cust_suppl : false,
      wallet_tmp: 0
    });
  }
  remove(index) {
    // if cost category ledger remove, then clear cost center section
    if(this.ccindex == index){
      this.isCostCategoryLedger = false;
      this.costcenterList = [];
      this.isCCContentLoading = false;
      this.ccindex = null;
    }
    this.pay.ledgers.splice(index, 1);
    this.updateTotoal();
  }

  createPay(){
    this.curForm = this.langText.create;
    this.nextId = '';
    this.getNextPaymentId();
    this.clearForm();
  }
  clearForm() {
    this.pay = {
      pay_no: '',
      doc_no:'',
      pay_date: new Date(),
      pay_acc: { ledger_id : 0 },
      note: '',
      ttlAmount: 0,
      grandTtlAmount: 0,
      ttlVatAmount: 0,
      pay_balance: 0,
      pay_bal_sign: '',
      godown_id: 0,

      ledgers: [{
        sub_id: '',
        ledger: { ledger_id : 0 },
        amount: '',
        narration: '',
        vatIncExc: 0,
        vat_per: 0,
        vat_amount: 0,
        actlAmount: 0,
        fnlAmount: 0,
        pay_balance: 0,
        pay_bal_sign: '',
        accLoading: false,
        supp_default_currency_id: 0,
        amount_sup_currency:<any>'',
        exchange_rate:1,
        selcurrency:<any>{},
        wallet_amt: 0,
        chk_cust_pay_wallet_effect:false,
        is_both_cust_suppl : false,
        wallet_tmp: 0
      }]
    };
    this.isCostCategoryLedger = false;
    this.costcenterList = [];
    this.isCCContentLoading = false;
    this.ccindex = null;
  }

  vatCalculate(index) {
    this.pay.ledgers[index].amount = this.pay.ledgers[index].amount || 0;
    if (this.pay.ledgers[index].vatIncExc == 0) {
      // this.pay.ledgers[index].fnlAmount = this.pay.ledgers[index].amount;
      this.pay.ledgers[index].vat_amount = (this.pay.ledgers[index].amount / 100 ) * this.pay.ledgers[index].vat_per;
      this.pay.ledgers[index].fnlAmount = Number(this.pay.ledgers[index].amount) + Number(this.pay.ledgers[index].vat_amount);
      this.pay.ledgers[index].actlAmount = this.pay.ledgers[index].amount;
    } else {
      this.pay.ledgers[index].actlAmount = this.pay.ledgers[index].amount / ( 1 + (this.pay.ledgers[index].vat_per / 100));
      this.pay.ledgers[index].vat_amount = (this.pay.ledgers[index].actlAmount  / 100 ) * this.pay.ledgers[index].vat_per;
      this.pay.ledgers[index].fnlAmount = this.pay.ledgers[index].amount;
    }
    this.updateTotoal();
    // alert(this.pay.ledgers[index].amount);

    // alert(this.pay.ledgers[index].vatIncExc);
    // alert(this.pay.ledgers[index].vat_per);
  }

  checkTabClicked(keycode) {
    console.log(keycode);
    if (keycode.keyCode === 9) {
      this.addMore();
    }
  }

  updateTotoal(){
    this.pay.ttlAmount = 0;
    this.pay.ttlVatAmount = 0;
    this.pay.grandTtlAmount = 0;
    this.pay.ledgers.forEach(element => {
      this.pay.grandTtlAmount += Number(element.fnlAmount);
      this.pay.ttlVatAmount += Number(element.vat_amount);
      this.pay.ttlAmount += Number(element.actlAmount);
    });
  }

  voidPayment(id, index){
    // const confrm = confirm('Do you wish to continue?');
    if (confirm('Are you sure to void this record ?')) {
      this.info[index].void_in_progress = true;
      this.apiService.voidPayment({pay_no: id}).subscribe((res) => {
        // console.log(this.staff);
        this.isSubmitInProg = false;
        if (res.error != null) {
          this.resultobj = res.error;
        } else {
          this.getInfo(this.curpage);
          $('.close').click();

          this.coreService.showMessage(res.message);
        }

      });
    }
  }

  editVoucher(pay_no){
    this.nextId = '';
    this.curForm = this.langText.update;
    this.clearForm();
    this.resultobj = [];
    this.apiService.getPayment({rec_no: pay_no}).subscribe((res) => {
        this.nextId = res.pay_no;
        const result = this.account.filter(acc => acc.ledger_id == res.pay_acc_ledger_id);
        this.pay.pay_no = res.pay_no;
        this.pay.pay_date = new Date(res.pay_datetime);
        this.pay.pay_acc =result[0];
        this.getAccLedgerBalance();
        this.pay.note = res.pay_note;
        this.pay.doc_no = res.pay_doc_no;
        this.pay.godown_id = res.godown_id;
        this.pay.ledgers = [];
        res.particulars.forEach((element,i) => {
          const result = this.nonAccount.filter(nonacc => nonacc.ledger_id == element.ledger_id);
          var tmpRow = {
            sub_id: element.paysub_id,
            ledger: result[0],
            amount: element.paysub_amount,
            narration: element.paysub_narration,
            vatIncExc: element.paysub_vat_inc,
            vat_per: element.paysub_vat_per,
            vat_amount: element.paysub_vat_amt,
            actlAmount: element.paysub_amount - element.paysub_vat_amt,
            // be care fully check if tax include exclud e option integrating
            fnlAmount: element.paysub_amount,
            pay_balance: 0,
            van_id: element.paysub_van_id,
            pay_bal_sign: '',
            accLoading: true,
            supp_default_currency_id: element.paysub_currency_id,
            exchange_rate: element.paysub_exchange_rate,
            amount_sup_currency: element.paysub_amount/element.paysub_exchange_rate,
            selcurrency:<any>{},
            wallet_amt: 0,
            chk_cust_pay_wallet_effect:false,
            is_both_cust_suppl : false,
            wallet_tmp: 0
          };
          this.pay.ledgers.push(tmpRow);
          this.getLedgerBalance(i, pay_no);
        });
        this.updateTotoal();
    });
  }

  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      
      this.vans = res['data'];
    });

  }

  searchAllVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      
      this.allvans = res['data'];
      this.vans = res['data'];
    });

  }
  validateAndSubmit() {
    this.resultobj = {};



    if (this.pay.pay_date == undefined) { 
      this.resultobj.pay_date = "t";
      this.resultobj.push({ 'pay_date': 'Required' });
    }
    //  else {
    //   if (this.pay.pay_date > new Date()) {
    //     this.resultobj.pay_date = "t";
    //     this.resultobj.push({ 'pay_date': 'Required' });
    //   } else {
    //     this.resultobj.pay_date = "f";
    //   }
    // }

    
    this.pay.ledgers.forEach(element => {
      if(element.wallet_amt > 0 && element.wallet_amt < Number(element.fnlAmount)){
        this.resultobj['ledgers.0.amount'] = ["Invalid Amount, Available wallet Amount is : " + element.wallet_amt.toFixed(this.count_dec) ];
      }
    });

     // Check if the selected account and ledger are the same by harsha
     if (this.pay.pay_acc.ledger_id && this.pay.ledgers.some(ledger => ledger.ledger.ledger_id === this.pay.pay_acc.ledger_id)) {
      this.resultobj['pay_acc.ledger_id'] = ["Error: The selected account and ledger cannot be the same."];
      console.log( this.resultobj['pay_acc.ledger_id']);
      
  }


    if (Object.keys(this.resultobj).length <= 0) {

    if (confirm('Do you wish to continue?')) {
      this.isSubmitInProg = true;
      let callFunction = '';
      if (this.curForm === this.langText.create) {
        callFunction = 'createPayment';
      }
      if (this.curForm === this.langText.update) {
        callFunction = 'updatePayment';
      }
      if(this.ccindex != null){
        this.pay.ledgers[this.ccindex].ledger.cc = this.costcenterList;
      }
      this.apiService[callFunction](this.pay).subscribe((res) => {
        // console.log(this.staff);
        this.isSubmitInProg = false;
        if (res.error != null) {
          this.resultobj = res.error;
          if(this.resultobj.doc_no){
            alert(this.resultobj.doc_no);
          }
        } else {
          this.closeModal.nativeElement.click();
          this.getInfo(this.curpage);
          this.coreService.showMessage(res.message);
          this.clearForm();
          this.getNextPaymentId();
          this.getAllCurrencies();
          if(callFunction == 'updatePayment'){
            this.getLogInfo(this.log_curpage);
          }
          this.resultobj = {};
        }

      });
    }
  }
  }

  viewPayment(id){
    this.payPriviewLoader = true;
    
      this.apiService.getPayment({rec_no: id}).subscribe((res) => {
    this.payPriviewLoader = false;
       
      this.printPreview = res;
      // console.log(this.printPreview.acc_led_name);
      
      // console.log(this.printPreview.rec_ttl_amount);

      });

  }
  viewLogPayment(res){
    this.log_printPreview = res;
  }
  //irfan
  removeName() {
    this.ledger_all = [];
    $('.prev-list').hide();
  }

  getAllGroup() {
    this.apiService.readAllGrp().subscribe((res: Group[]) => {
      // console.log(res['data']);
      const LedgGroup = res['data'].filter((group) => group.accgrp_id !== 21);
      this.group_all = LedgGroup;
    });
  }
  openModal(content: any, size) {

    this.modalRef = this.modalService.open(content, { size: size, backdrop: 'static' });
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  validateAndSubmit1(){
    this.isSubmitInProg1 = true;
    let callFunction = 'createCustomer';
    
    if(this.userType =='ADMIN'){
      this.customer.usr_type = 1;
    }else{
      this.customer.usr_type = 0;
    
    }
    this.customer.is_supplier = this.supplier;
    this.apiService[callFunction](this.customer).subscribe((res) => {
      // console.log(this.staff);
      this.isSubmitInProg1 = false;
      if (res.error != null) {
        this.resultobj = res.error;
      } else {
        
        this.coreService.showMessage(res.message);
        // this.clearForm();
        this.resultobj = {};
        this.modalRef.close();
        this.clearCustForm();
        this.supplier = false;
        this.customer_type = false;
        this.getAllNonAccountLedgers();
      }

    });
  }
  createSupplier(formgroup: { value: any; }) {
    this.validatesupplier(formgroup.value);
    if (this.printError.length <= 0) { 
      this.pageLoadingImg1 = true;
      
    if(this.userType =='ADMIN'){
      formgroup.value.usr_type = 1;
    }else{     
      formgroup.value.usr_type = 0;
    
    }

    this.apiService.createSupplier(formgroup.value).subscribe((res) => {
      this.pageLoadingImg1=false;

      if (res.error != null) {
        this.resultobj = res.error;
        this.resultobj = this.coreService.getValidation(this.resultobj);
      } else {
        this.coreService.createfunct(formgroup, this.resultobj, res.message);
        this.resultobj = {};
        this.modalRef.close();
        this.supplier = false;
        this.getAllNonAccountLedgers();
      }

    });
  }
  }
  validatesupplier(list) {
   
    this.printError = [];
    if (!list.supp_name) {
      this.printError.push({ 'supp_name': 'Required' });
      $('.supp_name').css("border", "1px solid red");
      $(".supp_name_err").text("Supplier name required");
    } else {
      $('.supp_name').css("border", "1px solid #dedede");
      $(".supp_name_err").text("");
    }
}
createLedger(formgroup: { value: any; }) {
  this.pageLoadingImg1 = true;
  this.apiService.createLedgerPopup(formgroup.value).subscribe((res: Ledger) => {
    this.pageLoadingImg1 = false;

    if (res.error != null) {
      this.resultobj = res.error;
      this.resultobj = this.coreService.getValidation(this.resultobj);
    }
    else {
      this.coreService.createfunct(formgroup, this.resultobj, res.message);
      this.resultobj = {};
      this.modalRef.close();
      this.getAllNonAccountLedgers();
    }

  });
}
searchLedger(search: string) {
  let searchval = new FormData();
  searchval.append("search", search);
  this.apiService.searchLedgerList(searchval, 1).subscribe((res) => {
    this.searchleadger = res['data']['data'];
    // console.log(this.searchleadger);
  });
  $('.prev-list').show();
}
clearCustForm() {
  this.customer = {
    is_supplier:false,
    id: '',
    ledger_id: '',
    name: '',
    alias: '',
    code: '',
    email : '',
    mobile: '',
    vat_no: '',
    op_bal: 0,
    op_bal_type: true,
    brch_id:0,
    usr_type:0,
    add: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    del: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    due_day: '',
    van_line: 0,
    price_group: 1,
    note: ''
  };
}

updateAmount(i){
  this.pay.ledgers[i].amount = (this.pay.ledgers[i].amount_sup_currency || 0) * this.pay.ledgers[i].exchange_rate;
  this.vatCalculate(i);
}


filterSearch(formdata:{ value: any; }) {
  this.pageLoadingImg =true;
this.apiService.searchPaymentList(formdata.value).subscribe((res) => {
  this.info = res.data.data;
  this.pageLoadingImg = false;
  this.pageFrom = res.data.from;
  this.curpage = res.data.current_page;
  this.lastpage = res.data.last_page;
  this.totalItem = res.data.total;
  this.pgEnd(this.curpage, this.lastpage);
  this.pgStart(this.curpage);
 });
}

}
