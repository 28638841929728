import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ProductionService } from '../../../../service/production.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-production',
  templateUrl: './production.component.html',
  styleUrls: ['./production.component.css']
})

export class ProductionComponent implements OnInit {

  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  // alias: any;
  prodctnId: any;
  prdn_date = new Date();
  step3 = false;
  step2 = false;
  step1 = false;
  searchExisting = true;
  calculationDisplay = true;
  netcost: any;
  prodform_id: any;
  ttlCommAmount: any;
  output_qty: any;
  prdAll = [];
  filterProdAll = [];
  selProdIds: any = {};
  formula_all: any;
  prdIngAll = [];
  filterIngrdProd = [];
  selIngProdIds: any = {};

  staffs = [];
  filterStaffs = [];
  selStaffs: any = {};

  commAll = 0;
  comsnCommAmnt = 0;
  resultobj: any = {};

  prodFormula = {
    // prdn_date:new Date(),
    productnId: 0,
    productnDate: 0,
    formula_id: '',
    active_stock: '',
    prdn_gd_id:0,
    formula_name: '',
    productOut: [],
    productIngd: [],
    isProdCommsn: false,
    // 0 for all and 1 for individual productIngd: [{
    staffs: [],
    insp_staff: null,
    ttlComm: 0,
    ttlPurchase: 0,
    miscExp: <any> '',
  };

  formula_list = [];
  info = [];
  isSubmitInProg = false;
  pageLoadingImg = false;
  errObjArr = {
    unitqtyErr: null, unitErr: null,
    prodErr: null, gdErr: null, pstkErr: null
  };
  curpage = 1;
  lastpage = 1;
  perpage = 5;
  totalItem = 0;
  godown_all = [];
  curForm = 'Create';
  searchStkBatch: any; purch_note: any; vantran_formid: any;
  up: any;
  from: any;
  loader: boolean;
  userdefltGwn: any;
  prdctn_gd_id: number;
  userEncpType: any;
  printresultobj: any = {};
  retData:any[];
  opData=[];
  retDataIncr:string[];
  totalCost:any;
  prdnFlag:any;
  previewLoader: boolean;
  branch_display_name: any;
  checkBatch =false;
  showSqmCalc = false;
  sqmCalc = [
    {
      height:0,
      width:0,
      qty:1,
      sqm:0
    }
  ]
  ttlSqm = 0;
  country_dec: string;
  edit_Production=false;
  cmpny: any;
  constructor(private productionService: ProductionService,  private datePipe: DatePipe,private apiService: ApiService,
    private coreService: CoreService,private translate: TranslateService,) { }

  ngOnInit() {
    this.cmpny = this.coreService.getUserData('cmpny');
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.up = this.coreService.getUserPrivilage();
    this.userEncpType = this.coreService.getUserData('usr_enc_rate_code');
    this.checkDisplaySQMCalc();
    this.prodform_id ='';
    this.getAllSatff();
    this.getInfo(this.curpage);
    this.listProductFormula();

    this.listGodowns();
    this.setProdId();

    this.apiService.getClientSettingBykey({ key: 'Batch Support' }).subscribe((res) => {
      if (res['data']) {
        this.checkBatch = (res['data']['cs_value']) ? true : false;
      }
    });

    // this.userdefltGwn =   Number(this.coreService.getUserData('usr_default_godown_id'));
    if(Number(this.coreService.getUserData('usr_default_godown_id')) > 0)
    this.userdefltGwn = Number(this.coreService.getUserData('usr_default_godown_id'));
    else
    this.userdefltGwn = 0;


    //this.listProductionFinished();
    if(Number(this.coreService.getUserData('usr_default_godown_id')) > 0)
    this.prdctn_gd_id=Number(this.coreService.getUserData('usr_default_godown_id'));
    else
    this.prdctn_gd_id=0;

    this.country_dec=this.coreService.setDecimalLength();
  }

  ngOnDestroy() {
    $('.close').click();

  }
  checkDisplaySQMCalc(){
    this.apiService.getClientSettingBykey({key: 'show_prod_sqm_calc'}).subscribe((res) => {
      if (res['data']) {
        this.showSqmCalc = (res['data']['cs_value']) ? true: false;
      } 
    });
  }
  production_search(search: string) {   
    this.previewLoader = true;
    const searchval = new FormData();
    searchval.append('prdn_id', search);
    this.productionService.prodDet(searchval).subscribe((res) => {
      this.previewLoader = false;      
      if (res.data.prdn_id && res.data.prdn_id != null) {
        this.printresultobj = res.data;           
        this.printresultobj = this.coreService.getValidation(this.printresultobj); 
        this.retData = null;      
      } else {
        this.printresultobj = {};        
        this.retData = res.data.op; 
        this.opData = res.data.op;
        this.prdnFlag = res.data.op[0].flag;
        this.totalCost = Number(this.opData[0].total_cost)+Number(this.opData[0].commission)+Number(this.opData[0].misc_exp);
        this.retDataIncr = res.data.increds;       
        
      }


    });
  }

  editProduction(search: string) { 
    this.curForm = 'Update';
    this.edit_Production =true;
    this.step1 =true; 
    this.step3 = false;
    this.step2 = false; 
    this.displayStep1();
    this.previewLoader = true;
    this.prodFormula.ttlPurchase = 0;
    this.prodFormula.ttlComm = 0;
    this.prodFormula.staffs = [];
    const searchval = new FormData();
    searchval.append('prdn_id', search);
    this.productionService.getProductionDet(searchval).subscribe((res) => {
    
      
      let i=0;
      this.prodctnId = res.data.prdn_id;
      // this.prodform_id =res.production[0].prdn_prodform_id;
      this.prodform_id =res.data.production_formula;
      this.prdctn_gd_id =res.increds[0].prdnsub_gd_id;
      this.output_qty =res.production[0].prdnprd_qty;
      // this.prdn_date =res.data.prdn_date;
      this.prdn_date =new Date(res.data.prdn_date);
      
    //   this.prodFormula.productOut[i].product.prd_name ="shameer";
      
    //   this.prodFormula.productOut[i].product.prd_name = res.production.prd_name;
    // this.prodFormula.productOut[i].product.prd_alias = res.production.prd_alias;
    // this.prodFormula.productOut[i].product.prd_name = new Date(Batchcode.sb_manufacture_date);
    // this.prodFormula.productOut[i].product.prd_name = 0;
    
    this.prodFormula.formula_id = res.data.prdn_prodform_id;
    this.prodFormula.productnId =res.data.prdn_id; 

    // this.prodFormula.active_stock = data.active_stock;
    this.prodFormula.formula_name = res.data.prodform_name;
    this.prodFormula.isProdCommsn = (res.data.prdn_commission === 1) ? true : false;
    this.prodFormula.ttlComm = res.data.prdn_comm_amount;
    this.prodFormula.miscExp = (res.data.prdn_misc_exp != 0) ? res.data.prdn_misc_exp : '';
    this.prodFormula.insp_staff = res.data.prdn_inspection_staff;
    this.prodFormula.prdn_gd_id = this.prdctn_gd_id;
    this.prodFormula.productOut = [];
    this.prodFormula.isProdCommsn =res.data.prdn_commission;
    this.prodFormula.active_stock = res.data.active_stock;
    this.step1 = true;
    this.purch_note =res.data.prdn_note;
     //this.output_qty =  res.production.prdnprd_qty;
  
    res.production.forEach((element, index) => {


     
      this.prodFormula.productOut.push({
        product: {
          prd_name: element.prd_name,
          prd_id: element.prd_id,
          unit_display: element.unit_display,
          prd_base_unit_id: element.prd_base_unit_id,
          // bs_prate: element.bs_prate,
          branch_stock_id: element.prdnprd_branch_stock_id,
          bs_stock_id: element.prdnprd_stock_id,
          gd_id: this.userdefltGwn,
          batches: element.batches,
          batch: element.batch,
          batch_code:(element.batch) ? element.batch.sb_batch_code : '',
          manf_date:(element.batch) ? new Date(element.batch.sb_manufacture_date) : 0,
          exp_date:(element.batch) ? new Date(element.batch.sb_expiry_date) : 0,
          sb_id:(element.batch) ? element.batch.sb_id : 0,

        },

       
       
        edit_qty: element.outputQty,
        qty: element.prdnprd_qty,
        qty_expected: element.prdnprd_qty,
        percntg: element.percntg,
        qty_received: element.prdnprd_qty,
        
        rate_expected: 0,
        net_rate: 0,
        // exp_date: 0,
        // manf_date: 0,
        // batch_code: '',
        gd_id: this.userdefltGwn,
      });

      this.selectBatch(element.batch,index);
      this.selProdIds[index] = element.prd_id;
    });
    this.filterProducts();
    this.prodFormula.productIngd = [];

    res.increds.forEach((element, index) => {

      this.prodFormula.productIngd.push({
        product: {
          prd_name: element.prd_name,
          prd_id: element.prd_id,
          unit_display: element.unit_display,
          prd_base_unit_id: element.prd_base_unit_id,
          bs_prate: (this.userEncpType ? this.coreService.splitToDigit(element.prdnsub_rate) : element.prdnsub_rate) ,
          shop: element.bs_stock_quantity_shop,
          shop_available: element.bs_stock_quantity_shop,
          branch_stock_id: element.branch_stock_id,
          bs_stock_id: element.bs_stock_id,
          //units: element.units,
          gd_id : this.prdctn_gd_id,
          batch:element.batch,
          batches:element.batches,
          batch_code:(element.batch) ? element.batch.sb_batch_code : '',
          manf_date:(element.batch) ? new Date(element.batch.sb_manufacture_date) : 0,
          exp_date:(element.batch) ? new Date(element.batch.sb_expiry_date) : 0,
          sb_id:(element.batch) ? element.batch.sb_id : 0,
         // default_unit: { "unit_name": element.unit_display, "sur_unit_id": element.prd_base_unit_id },
          default_unit: { "unit_name": element.unit_display, "sur_unit_id": element.prd_base_unit_id,"unit_base_qty":1 },

        },

       
        // qty: element.prodformsub_qty * output_qty,
        // qty_show: element.prodformsub_qty * output_qty,
        qty:element.prdnsub_qty,
        qty_show:element.prdnsub_qty,
        // qty: (element.prodformsub_qty / data.prod[0]['prodformprod_qty'])*output_qty,
        // qty_show:  (element.prodformsub_qty/data.prod[0]['prodformprod_qty'])*output_qty,
        amount: element.prdnsub_rate * element.prdnsub_qty,
      });
      this.selectIngredientBatch(element.batch,index);
      this.selIngProdIds[index] = element.prd_id;
    });
    this.updatePurchaseTtl();

    this.getAllSatff();
    // this.prodFormula.staffs =res.data.commision;

    let isCommComsn = true;
    res.data.commision.forEach((element, index) => {
          this.prodFormula.staffs.push({
            staff: { ledger_id: element.ledger_id, ledger_name: element.ledger_name },
            comm: element.prdncomm_in
          });
          if (res.data.commision[index + 1]) {
            if (element.prdncomm_in !== res.data.commision[index + 1].prdncomm_in) {
              isCommComsn = false;
            }
          }
          this.selStaffs[index] = element.ledger_id;
        });

    if (isCommComsn && (this.prodFormula.staffs.length === this.staffs.length)) {
      this.commAll = 1;
      this.comsnCommAmnt = this.prodFormula.staffs[0].prdncomm_in;
    } else {
      this.commAll = 0;
    }
    this.updateCommsnTtl();
    this.prodFormula.miscExp =res.data.prdn_misc_exp;
    // this.prodFormula.insp_staff =res.data.inspection_staff_name;
    this.prodFormula.insp_staff =res.data.inspection_ledger;


    


    });
  }

  setProdId() {
    let searchval = new FormData();
    this.apiService.geProdctnId(searchval).subscribe((res) => {
      this.prodctnId = res['data']['prod_id'];
    });
  }
  listGodowns() {
    let searchval = new FormData();

    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godown_all = res['data'];
      // this.godown_all.push({ "gd_id": 0, 'gd_name': 'Shop' });


    });

  }

  searchForm(search: string) {
    let searchval = new FormData();
    searchval.append("gd_name", search);
    this.apiService.getGodownByName(searchval).subscribe((res) => {
      this.godown_all = res['data'];
      this.godown_all.push({ "gd_id": 0, 'gd_name': 'Shop' });

    });

  }
  clearForm() {
    this.curForm = 'Create';
    this.clearPopUp();
  }
  displayStep2() {
    this.resultobj = [];

    if (this.prdn_date == undefined) {
      this.resultobj.prd_date = "t";
      this.resultobj.push({ 'prd_date': 'Required' });
    }
    else {
      if (this.prdn_date > new Date()) {
        this.resultobj.prd_date = "t";
        this.resultobj.push({ 'prd_date': 'Required' });
      } else {
        this.resultobj.prd_date = "f";
      }
    }
    if (this.resultobj.length <= 0) {
      this.step2 = true;
      this.calculationDisplay = false;
    }
  }
  displayStep1() {
    this.step2 = false;
    this.calculationDisplay = true;
  }

  displayStep3() {


    this.step3 = true;
    this.step1 = this.step2 = false;
    this.calculationDisplay = false;

  }

  clearPopUp() {
    this.resultobj = {};
    this.prodFormula = {
      // prdn_date:new Date(),
      
      productnId: 0,
      productnDate: 0,
      formula_id: '',
      formula_name: '',
    prdn_gd_id:0,

      productOut: [],
      productIngd: [],
      isProdCommsn: false,
      // 0 for all and 1 for individual productIngd: [{
      staffs: [],
      insp_staff: null,
      ttlComm: 0,
      ttlPurchase: 0,
      miscExp: '',
      active_stock: ''
    };
    this.output_qty ='';
    this.prodform_id ='';
    this.prodFormula.ttlPurchase = 0;
    this.prodFormula.miscExp = '';
    this.filterProdAll = [];
    this.selProdIds = {};
    this.filterIngrdProd = [];
    this.selIngProdIds = {};
    this.filterStaffs = [];
    this.selStaffs = {};
    this.setProdId();
    this.step3 = false;
    this.step2 = false;
    this.step1 = true;
    this.displayStep1();


  }
  productionPreview(prdn_id) {
    this.prodFormula = {
      // prdn_date:new Date(),
      productnId: 0,
      productnDate: 0,
      formula_id: '',
      formula_name: '',
    prdn_gd_id:0,

      productOut: [],
      productIngd: [],
      isProdCommsn: false,
      // 0 for all and 1 for individual productIngd: [{
      staffs: [],
      insp_staff: null,
      ttlComm: 0,
      ttlPurchase: 0,
      miscExp: '',
      active_stock: ''
    };
    let searchval = new FormData();
    searchval.append("prdn_id", prdn_id);
    this.apiService.productionPreview(searchval).subscribe((res) => {
      this.prodFormula = res['data'];

      //this.godown_all.push({ "gd_id": 0, 'gd_name': 'Shop' });

    });

  }
  getSingleItem(data, output_qty = 1) {
    
    

    console.log("qty");
    console.log(output_qty);

    if (data.prodform_id) {
      //this.clearPopUp();
      this.step1 = true;
      this.output_qty = output_qty;
      this.prodFormula.formula_id = data.prodform_id;
      this.prodFormula.prdn_gd_id = this.prdctn_gd_id;

     // this.isSubmitInProg = true;
      // this.curForm = 'Update';
      //this.clearPopUp();
      this.loader = true;
      // console.log(data.prodform_id);
      this.productionService.getNewProduction(data.prodform_id,this.prdctn_gd_id).subscribe((res) => {
        //this.isSubmitInProg = false;
        const data = res.data;
        this.loader = false;

        // this.prodFormula.prdn_date = new Date();
        this.prodFormula.formula_id = data.prodform_id;
        this.prodFormula.active_stock = data.active_stock;
        this.prodFormula.formula_name = data.prodform_name;
        this.prodFormula.isProdCommsn = (data.prodform_commission === 1) ? true : false;
        this.prodFormula.ttlComm = data.prodform_comm_amount;
        this.prodFormula.miscExp = (data.prodform_misc != 0) ? data.prodform_misc : '';
        this.prodFormula.insp_staff = data.staff;
        this.prodFormula.prdn_gd_id = this.prdctn_gd_id;
        this.prodFormula.productOut = [];
        data.prod.forEach((element, index) => {
          this.prodFormula.productOut.push({
            product: {
              prd_name: element.prd_name,
              prd_id: element.prd_id,
              unit_display: element.unit_display,
              prd_base_unit_id: element.prd_base_unit_id,
              bs_prate: element.bs_prate,
              branch_stock_id: element.branch_stock_id,
              bs_stock_id: element.bs_stock_id,
              gd_id: this.userdefltGwn,
              batches: element.batches,
              batch: element.batch,

            },
            qty: element.prodformprod_qty,
            qty_expected: element.prodformprod_qty * (output_qty / data.prod[0]['prodformprod_qty']),
            percntg: element.prodformprod_perc,
            qty_received: element.prodformprod_qty * (output_qty / data.prod[0]['prodformprod_qty']),
            rate_expected: 0,
            net_rate: 0,
            exp_date: 0,
            manf_date: 0,
            batch_code: '',
            gd_id: this.userdefltGwn,
          });
          this.selProdIds[index] = element.prd_id;
        });
        this.filterProducts();
        this.prodFormula.productIngd = [];


        data.sub.forEach((element, index) => {

          this.prodFormula.productIngd.push({
            product: {
              prd_name: element.prd_name,
              prd_id: element.prd_id,
              unit_display: element.unit_display,
              prd_base_unit_id: element.prd_base_unit_id,
              bs_prate: (this.userEncpType ? this.coreService.splitToDigit(element.bs_prate) : element.bs_prate) ,
              shop: element.bs_stock_quantity_shop,
              shop_available: element.bs_stock_quantity_shop,
              branch_stock_id: element.branch_stock_id,
              bs_stock_id: element.bs_stock_id,
              units: element.units,
              gd_id : this.prdctn_gd_id,
              batches: element.batches,
              batch:element.batch,
             // default_unit: { "unit_name": element.unit_display, "sur_unit_id": element.prd_base_unit_id },
              default_unit: { "unit_name": element.unit_display, "sur_unit_id": element.prd_base_unit_id,"unit_base_qty":1 },

            },
            // qty: element.prodformsub_qty * output_qty,
            // qty_show: element.prodformsub_qty * output_qty,
            qty: (element.prodformsub_qty / data.prod[0]['prodformprod_qty'])*output_qty,
            qty_show:  (element.prodformsub_qty/data.prod[0]['prodformprod_qty'])*output_qty,
            amount: (this.userEncpType ? this.coreService.splitToDigit((( (element.prodformsub_qty/data.prod[0]['prodformprod_qty'])*output_qty) * element.bs_prate))  :(( (element.prodformsub_qty/data.prod[0]['prodformprod_qty'])*output_qty) * element.bs_prate) )
          });
          this.selIngProdIds[index] = element.prd_id;
        });
        this.updatePurchaseTtl();
        this.filterIngProducts();
        this.prodFormula.staffs = [];
        let isCommComsn = true;
        data.commision.forEach((element, index) => {
          this.prodFormula.staffs.push({
            staff: { ledger_id: element.ledger_id, ledger_name: element.ledger_name },
            comm: element.prodformcomm_amount
          });
          if (data.commision[index + 1]) {
            if (element.prodformcomm_amount !== data.commision[index + 1].prodformcomm_amount) {
              isCommComsn = false;
            }
          }
          this.selStaffs[index] = element.ledger_id;
        });
        this.filterStaff();
        if (isCommComsn && (this.prodFormula.staffs.length === this.staffs.length)) {
          this.commAll = 1;
          this.comsnCommAmnt = this.prodFormula.staffs[0].comm;
        } else {
          this.commAll = 0;
        }
      });

    }
  }

  selectBatch(Batchcode, index) {
    
    if(Batchcode != null){

      this.prodFormula.productOut[index]['batch_code'] = Batchcode.sb_batch_code;
      this.prodFormula.productOut[index]['exp_date'] = new Date(Batchcode.sb_expiry_date);
      this.prodFormula.productOut[index]['manf_date'] = new Date(Batchcode.sb_manufacture_date);
      this.prodFormula.productOut[index]['gd_id'] = this.prdctn_gd_id;
      //this.prodFormula.productOut[index]['gd_id'] = 0;
      this.prodFormula.productOut[index]['sb_id'] = Batchcode.sb_id;

    }
    else{

      this.prodFormula.productOut[index]['batch_code'] = '';
      this.prodFormula.productOut[index]['exp_date'] = 0;
      this.prodFormula.productOut[index]['manf_date'] = 0;
      this.prodFormula.productOut[index]['gd_id'] = this.prdctn_gd_id;
      this.prodFormula.productOut[index]['sb_id'] = 0;

    }
 

  }

  selectIngredientBatch(Batchcode, index) {

    if(Batchcode != null){

      this.prodFormula.productIngd[index]['batch_code'] = Batchcode.sb_batch_code;
      this.prodFormula.productIngd[index]['exp_date'] = new Date(Batchcode.sb_expiry_date);
      this.prodFormula.productIngd[index]['manf_date'] = new Date(Batchcode.sb_manufacture_date);
      this.prodFormula.productIngd[index]['gd_id'] = this.prdctn_gd_id;
      //this.prodFormula.productIngd[index]['gd_id'] = 0;
      this.prodFormula.productIngd[index]['sb_id'] = Batchcode.sb_id;
  
      
      // if (this.prdctn_gd_id) {
        // this.selctedProd.stock_qty = 333
        // getGodownBatchStock(){}
        const searchval = new FormData();
        searchval.append("prd_id", this.prodFormula.productIngd[index].product.prd_id);
        searchval.append("gd_id", String(this.prdctn_gd_id));
        searchval.append("price_group_id","0");
        searchval.append("batch_id", Batchcode.sb_id);
    
        this.apiService.getGodownBatchStock(searchval).subscribe((resp) => {
  
          // this.salesItems[i].stock_qty = resp['data']['gdStock'].gbs_qty || 0;
          // this.salesItems[i].prod_units =resp['data']['prod_units'];
  
          this.prodFormula.productIngd[index].product.shop =
            resp["data"]["gdStock"].gbs_qty || 0;
  
        });
      // } 

    }
    else{

     
      this.prodFormula.productIngd[index]['batch_code'] = '';
      this.prodFormula.productIngd[index]['exp_date'] = 0;
      this.prodFormula.productIngd[index]['manf_date'] =0;
      this.prodFormula.productIngd[index]['gd_id'] = this.prdctn_gd_id;
      //this.prodFormula.productIngd[index]['gd_id'] = 0;
      this.prodFormula.productIngd[index]['sb_id'] = 0;

      const searchval = new FormData();
      searchval.append("prd_id", this.prodFormula.productIngd[index].product.prd_id);
      searchval.append("gd_id", String(this.prdctn_gd_id));
      searchval.append("price_group_id","0");
      
  
      this.apiService.getGodownStockQty(searchval).subscribe((resp) => {
     
      
        this.prodFormula.productIngd[index].product.shop =
        resp.bs_stock_quantity_shop;

        this.prodFormula.productIngd[index].product.shop_available =
        resp.bs_stock_quantity_shop;

      });

    }

  


  }



  changeUnit(qty, index) {
    //this.prodFormula.productIngd[index]['product']['shop'] = this.prodFormula.productIngd[index]['product']['shop_available'] * qty;
    this.prodFormula.productIngd[index]['qty'] *= qty;
  }

  getInfo(pageNo) {
    this.pageLoadingImg = true;
    this.productionService.getAllProductions(pageNo, this.perpage).subscribe((res) => {
      this.formula_list = res.data.data;
      this.pageLoadingImg = false;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
      this.from = res.data.from;
    });
  }

  listProductionFinished() {
    this.pageLoadingImg = true;
    this.productionService.getAllProductions(1, 5000).subscribe((res) => {
      this.formula_list = res.data.data;

    });
  }

  listProductFormula() {
    this.pageLoadingImg = true;
    this.productionService.getAllProductionFormula(1, 5000).subscribe((res) => {
      this.formula_all = res.data.data;
    });
  }
  searchFormula(search: string) {
    const searchval = new FormData();
    searchval.append('formula_name', search);
    this.productionService.searchProductionFormula(searchval).subscribe((res) => {
      this.formula_all = res.data;
    });
  }

  searchProducts(search: string) {
    const searchval = new FormData();
    searchval.append('prod_name', search);
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.prdAll = res.data;
      this.filterProducts();
    });
  }

  setValues(prodId, index) {
    if (prodId == null) {
      delete this.selProdIds[index];
    } else {
      this.selProdIds[index] = prodId;
    }
    this.filterProducts();
  }

  filterProducts() {
    this.filterProdAll = this.prdAll.filter(e => {
      if (Object.values(this.selProdIds).indexOf(e.prd_id) < 0) {
        return true;
      }
    });
  }

  addMore() {
    this.prodFormula.productOut.push({
      product: null,
      qty: 0,
      qty_expected: 0,
      percntg: 0,
      qty_received: 0,
      rate_expected: 0,
      net_rate: 0,
      exp_date: 0,
      manf_date: 0,
      batch_code: ''
    });
  }

  removeItem(index) {
    this.prodFormula.productOut.splice(index, 1);
    delete this.selProdIds[index];
    this.filterProducts();
  }
  searchIngProducts(search: string) {
    const searchval = new FormData();
    searchval.append('prod_name', search);
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.prdIngAll = res.data;
      this.filterIngProducts();
    });
  }

  setIngValues(prodId, index) {
    if (prodId == null) {
      delete this.selIngProdIds[index];
    } else {
      this.selIngProdIds[index] = prodId;
    }
    this.filterIngProducts();
  }

  filterIngProducts() {
    this.filterIngrdProd = this.prdIngAll.filter(e => {
      if (Object.values(this.selIngProdIds).indexOf(e.prd_id) < 0) {
        return true;
      }
    });
  }

  addMoreIngrd() {
    this.prodFormula.productIngd.push({
      product: null,
      qty_show: 0,
      qty: 0,
      amount: 0,
    });
  }

  removeIngd(index) {
    if (this.prodFormula.productIngd.length > 1) {
      this.prodFormula.productIngd.splice(index, 1);
    } else {
      // error atleast 1 item required
    }
    delete this.selIngProdIds[index];
    this.filterIngProducts();
  }

  commissionChk(value) {
    if (value === 0) {
      this.prodFormula.staffs = [];
      this.prodFormula.ttlComm = 0;
    }
  }

  setStaff(staffId, index) {
    if (staffId == null) {
      delete this.selStaffs[index];
    } else {
      this.selStaffs[index] = staffId;
    }
    this.filterStaff();
  }

  filterStaff() {
    this.filterStaffs = this.staffs.filter(e => {
      if (Object.values(this.selStaffs).indexOf(e.ledger_id) < 0) {
        return true;
      }
    });
  }

  getAllSatff() {
    this.apiService.listAllActiveStaff().subscribe((res) => {
      this.staffs = res.data;
      this.filterStaff();
    });
  }

  addMoreStaff() {
    this.prodFormula.staffs.push({
      staff: null,
      comm: 0,
    });
  }

  removeStaff(index) {
    // if (this.prodFormula.staffs.length > 1) {
    //   this.prodFormula.staffs.splice(index, 1);
    // } else {
    // error atleast 1 item required
    // }
    this.prodFormula.staffs.splice(index, 1);
    delete this.selStaffs[index];
    this.filterStaff();
    this.updateCommsnTtl();
  }

  showAllStaff() {
    this.selStaffs = {};
    this.prodFormula.staffs = [];
    this.staffs.forEach((element, index) => {
      this.prodFormula.staffs.push({
        staff: element,
        comm: 0,
      });
      this.setStaff(element.ledger_id, index);
    });
    this.updateCommsnTtl();
  }

  showIndivStaff() {
    this.selStaffs = {};
    this.filterStaff();
    this.prodFormula.staffs = [{
      staff: {},
      comm: 0,
    }];
  }
  updateOutPutProducts(qty, index) {

    // this.prodFormula.ttlComm = 0;
    // this.prodFormula.staffs.forEach(element => {
    //   this.prodFormula.ttlComm += parseFloat(element.comm.toString());
    // });
  }
  updateCommsnTtl() {
    this.prodFormula.ttlComm = 0;
    this.prodFormula.staffs.forEach(element => {
      if (parseFloat(element.comm.toString()) > 0) {
        this.prodFormula.ttlComm += parseFloat(element.comm.toString());
      }

    });
  }
  updatePurchaseTtl() {
    this.prodFormula.ttlPurchase = 0;
    this.prodFormula.productIngd.forEach(element => {
      this.prodFormula.ttlPurchase += element.amount;
    });
  }

  setIndvComsn() {

    this.prodFormula.staffs.forEach((element, index) => {
      this.prodFormula.ttlComm += parseFloat(element.comm.toString());
      this.prodFormula.staffs[index].comm = this.comsnCommAmnt;
    });
    this.updateCommsnTtl();
  }
  validateAndSubmit() {

    this.isSubmitInProg = true;
    let callFunction = '';
    if (this.curForm === 'Create') {
      callFunction = 'createProduction';
    }
    if (this.curForm === 'Update') {
      callFunction = 'updateProduction';
    }
    // exp_date: 0
    // manf_date: 0

    this.prodFormula.miscExp = this.prodFormula.miscExp || 0;
    this.prodFormula.productOut.forEach((element, index) => {
      this.prodFormula.productOut[index].exp_date = (this.prodFormula.productOut[index].exp_date === 0) ? 0 : this.apiService.formatDate(this.prodFormula.productOut[index].exp_date);
      this.prodFormula.productOut[index].manf_date = (this.prodFormula.productOut[index].manf_date === 0) ? 0 : this.apiService.formatDate(this.prodFormula.productOut[index].manf_date);
      this.prodFormula.productOut[index]['rate_expected'] = ((this.prodFormula.ttlComm * 1) + (this.prodFormula.ttlPurchase) + (this.prodFormula.miscExp * 1)) * (this.prodFormula.productOut[index].percntg / (100 * this.prodFormula.productOut[index].qty_expected));
      this.prodFormula.productOut[index]['net_rate'] = ((this.prodFormula.ttlComm * 1) + (this.prodFormula.ttlPurchase) + (this.prodFormula.miscExp * 1)) * (this.prodFormula.productOut[index].percntg / (100 * this.prodFormula.productOut[index].qty_received));
    });
    this.prodFormula['prdn_date'] = this.prdn_date;
    this.prodFormula['prdn_note'] = this.purch_note;

    this.productionService[callFunction](this.prodFormula).subscribe((res) => {

      this.isSubmitInProg = false;
      if (res.error != null) {
        this.resultobj = res.error;
      } else {
        //this.closeModal.nativeElement.click();
        //this.clearPopUp();
        this.prodFormula.productnId = res.preview.prdn_id;
        this.prodFormula.productnDate = res.preview.prdn_date;
        this.getInfo(this.curpage);
        this.coreService.showMessage(res.message);
        this.displayStep3();
        this.resultobj = {};
        this.purch_note = '';
        this.prodform_id = '';
        this.output_qty = 0;

      }

    });
  }

  updateToatalPurchaseAmount(qty, rate, base_qty, index) {

    this.prodFormula.productIngd[index]['product']['bs_prate'] = rate;
    this.prodFormula.productIngd[index]['qty'] = qty * base_qty;
    this.prodFormula.productIngd[index]['amount'] = qty * rate * base_qty;
    this.updatePurchaseTtl();

  }
  expectedQuantityChange(qty, index) {

    this.prodFormula.productOut[index]['qty_received'] = qty;
    this.prodFormula.productOut[index]['net_rate'] = ((this.prodFormula.ttlComm * 1) + (this.prodFormula.ttlPurchase) + (this.prodFormula.miscExp * 1)) * (this.prodFormula.productOut[index].percntg / (100 * this.prodFormula.productOut[index].qty));
    // console.log(  this.prodFormula.productOut[index]['qty_received']);
    // console.log(  this.prodFormula.productOut[index]['net_rate']+")))");

  }
  expectedRateChange(rate, index) {

    this.prodFormula.productOut[index]['net_rate'] = rate;
  }

  newProduction() {
    this.closeModal.nativeElement.click();
    this.step3 = false;
    this.step2 = false;
    this.calculationDisplay = true;
    this.clearPopUp();
  }

  selGd(gdid) {
    console.log(gdid+" Godown selected")
    //}
  }

  addMoreRow() {
    this.sqmCalc.push(
      {
        height:0,
        width:0,
        qty:1,
        sqm:0
      }
    );
  }

  remove(index) {
    this.sqmCalc.splice(index, 1);
    this.updateTotoal();
  }
  sqmCalculate(index){
    this.sqmCalc[index].sqm = this.sqmCalc[index].height * this.sqmCalc[index].width * this.sqmCalc[index].qty / 10000;
    this.updateTotoal();
  }
  updateTotoal(){
    this.ttlSqm = 0;
    this.sqmCalc.forEach(element => {
      this.ttlSqm += Number(element.sqm);
    });
  }
  clearAll(){
    this.sqmCalc = [
      {
        height:0,
        width:0,
        qty:1,
        sqm:0
      }
    ]
    this.updateTotoal();
  }
  apply(){
    this.output_qty = this.ttlSqm;
    $('.closbtn').parents('.modal:first').hide();
    $('#btnsqlcalc').click();
  }


}
